<template>
	<Slider fullscreen="true" numbered="true" :slides="slides"></Slider>
	<div class="cta">
		<router-link :to="{ name: user ? 'Welcome' : 'Login' }" class="btn">
			{{ $t( 'home.understand-connect' ) }}
		</router-link>

	</div>
</template>

<script>
import { mapState } from 'vuex';
import Slider from '@/components/content/Slider.vue';

export default {
	components: {
		Slider,
	},
	computed: {
		...mapState('authentication', [ 'user' ]),
		slides() {
			return [
				{
					id: '1',
					title: this.$t('steps.title-1'),
					text: this.$t('steps.text-1'),
					img: 'https://picsum.photos/375/460',
				},
				{
					id: '2',
					title: this.$t('steps.title-2'),
					text: this.$t('steps.text-2'),
					img: 'https://picsum.photos/375/465',
				},
				{
					id: '3',
					title: this.$t('steps.title-3'),
					text: this.$t('steps.text-3'),
					img: 'https://picsum.photos/375/470',
				},
				{
					id: '4',
					title: this.$t('steps.title-4'),
					text: this.$t('steps.text-4'),
					img: 'https://picsum.photos/375/475',
				},
			];
		},
	},
};
</script>

<style lang="scss" scoped>
	@use "./src/assets/scss/abstracts" as *;

	.cta {
		display: flex;
		align-items: center;
		justify-content: center;
		height: var(--container-cta-login-height);
		background-color: var(--color-white);
	}
</style>
