<template>
	<label :for="id" v-if="label">{{ label }}</label>
	<label :for="id" v-else>{{ $t( 'password.label' )}}</label>
	<div class="add">
		<input
			:type="[showPassword ? 'text' : 'password']"
			:id="id"
			:value="modelValue"
			@input="$emit('update:modelValue', $event.target.value)"
			:aria-describedby="showPattern ? 'pwdPattern userPwdError' : 'userPwdError'"
			:pattern="showPattern ? '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$' : null"
			required
		>
		<button
			type="button"
			class="btn btn--ghost"
			:aria-controls="id"
			:aria-label="[showPassword ? $t( 'password.hide' ) : $t( 'password.show' )]"
			@click="showPassword = !showPassword"
			style="--px: 0"
		>
			<icon-eye v-if="showPassword"></icon-eye>
			<icon-crossed-eye v-else></icon-crossed-eye>
		</button>
	</div>
	<span id="pwdPattern" class="message" v-if="showPattern">{{ $t( 'password.pattern' ) }}</span>
	<span class="sr-only" aria-live="polite">
		{{ showPassword ? $t( 'password.shown' ) : $t( 'password.hidden' ) }}
	</span>
</template>

<script>
import { IconEye, IconCrossedEye } from '@/components/icons';

export default {
	data: () => ({
		showPassword: false,
		id: null,
	}),
	props: [ 'label', 'showPattern', 'modelValue' ],
	components: {
		IconEye,
		IconCrossedEye,
	},
	mounted() {
		this.id = (new Date()).getTime();
	},
};
</script>
