<template>
	<article :aria-label="$t('operation.title')+' '+slip.number">
		<dl>
			<div class="icon">
				<icon-euro-refresh></icon-euro-refresh>
			</div>
			<div class="header">
				<div class="fs-3" aria-hidden="true">{{ formatedLongDate(slip.createdAt) }}</div>
				<div class="mt-a">
					<dt :aria-label="$t('operation.number')"></dt>
					<dd class="font-serif text-break"><span aria-hidden="true">{{ $t('operation.n') }}</span>&nbsp;{{ slip.number }}</dd>
				</div>
			</div>
			<button
				type="button"
				class="btn btn--ghost"
				:aria-expanded="showContent ? 'true' : 'false'"
				:aria-label="[showContent ? $t( 'operation.hide-content' ) : $t( 'operation.show-content' )]"
				@click="showContent = !showContent"
			>
				<icon-chevron-down></icon-chevron-down>
			</button>
			<div class="content" :hidden="!showContent ? 'true' : null">
				<div style="grid-column: 1/-1">
					<dt>{{ $t('operation.amount') }}</dt>
					<dd>{{ slip.totalTTC }} €</dd>
				</div>
				<div>
					<dt>{{ $t('operation.refund_at') }}</dt>
					<dd>{{ formatedShortDate(slip.refundAt) }}</dd>
				</div>
				<div style="--c: var(--color-slip)">
					<dt>{{ $t('operation.refund') }}</dt>
					<dd class="fw-bold">{{ slip.totalRefund }} €</dd>
				</div>
			</div>
		</dl>
	</article>
</template>

<script>
import { IconEuroRefresh, IconChevronDown } from '@/components/icons';

export default {
	props: [ 'slip' ],
	data: () => ({
		showContent: false,
	}),
	components: {
		IconEuroRefresh,
		IconChevronDown,
	},
	methods: {
		formatedShortDate(date) {
			if (date) {
				return date.toLocaleDateString(this.$i18n.locale, {
					year: 'numeric',
					month: '2-digit',
					day: '2-digit',
				});
			}

			return null;
		},
		formatedLongDate(date) {
			if (date) {
				return date.toLocaleDateString(this.$i18n.locale, {
					year: 'numeric',
					month: 'long',
					day: 'numeric',
				});
			}

			return null;
		},
	},
};
</script>

<style lang="scss" scoped>
	@use "./src/assets/scss/abstracts" as *;

	article {
		--icon-size: #{rem(48px)};
		--gap: var(--sp);
		padding: var(--gap);
		border-radius: rem(16px);
		background-color: var(--color-grey-1);
	}

	.icon {
		display: flex;
		align-items: center;
		justify-content: center;
		height: var(--icon-size);
		border-radius: rem(8px);
		font-size: rem(19px);
		background-color: var(--color-primary);
		color: var(--color-white);
	}

	dl {
		display: grid;
		gap: var(--gap);
		grid-template-columns: var(--icon-size) 1fr auto;
		margin: 0;
		word-break: break-all;
	}

	dt {
		font-weight: $w-semibold;
		font-size: 0.8em;
		color: var(--c, var(--color-passport));
	}

	dd {
		margin: 0;
		color: var(--c, inherit);
	}

	.header {
		display: flex;
		flex-direction: column;
		row-gap: var(--sp-0\.5);
	}

	.content {
		display: grid;
		gap: var(--gap);
		grid-template-columns: 1fr 1fr;
		grid-column: 1/-1;
		padding-top: var(--gap);
		border-top: rem(1px) solid var(--color-grey-2);
		font-size: $fs-3;
	}

	.btn {
		--fs: #{$fs-3};
		align-self: center;

		svg {
			transform: scaleY(var(--i, 1));
			transition: transform .2s;
		}

		&[aria-expanded="true"] {
			--i: -1;
		}
	}
</style>
