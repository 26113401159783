<template>
	<svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.71581 12.81145">
		<path d="M10.30909 12.81145a.75.75 0 0 1-.53027-1.28028l5.12645-5.12646-5.12645-5.12647A.74992.74992 0 0 1 10.83936.2177l5.65673 5.65672.00027.00027a.74973.74973 0 0 1-.00027 1.06028l-5.65673 5.65675a.7476.7476 0 0 1-.53028.21973Z"/>
		<path d="M0 6.38911a.75.75 0 0 1 .75-.75h14.5a.75.75 0 0 1 0 1.5H.75a.75.75 0 0 1-.75-.75Z"/>
	</svg>
</template>

<style scoped>
	[dir="rtl"] svg {
		transform: scaleX(-1);
	}
</style>
