<template>
	<div>
		<FormMessage v-if="errorCode" :message="$t('error.' + errorCode)" type="error" />
		<p class="fs-3">{{ $t('misc.required-fields') }}</p>
		<form @submit.prevent="submitForm">
			<div class="row">
				<div class="col-6">
					<div class="form-group" :class="{ 'error': v$.form.email.$error }">
						<label for="userEmailName">
							{{ $t('register.your-email') }}
							<abbr aria-hidden="true" title="Champ obligatoire">*</abbr>
						</label>
						<input
							type="email"
							id="userEmailName"
							autocomplete="email"
							:aria-describedby="v$.form.email.$error ? 'userEmailError' : null" v-model="form.email"
							required
						>
						<span id="userEmailError" class="message error" v-if="v$.form.email.$error">
							<template v-if="v$.form.email.required.$invalid">{{ $t('misc.is-required-field') }}</template>
							<template v-if="v$.form.email.email.$invalid">{{ $t('misc.invalid-email') }}</template>
						</span>
					</div>
				</div>
				<div class="col-6">
					<div class="form-group" :class="{ 'error': v$.form.password.$error }">
						<InputPassword v-model="form.password" showPattern="true"></InputPassword>
						<span id="userPwdError" class="message error" v-if="v$.form.password.$error">
							<template v-if="v$.form.password.required.$invalid">{{ $t('misc.is-required-field') }}</template>
						</span>
					</div>
				</div>
				<div class="col-6 text-right">
					<button type="submit" class="btn" :class="{ 'btn--loading': isLoading }">
						{{ $t('register.validate') }}
						<icon-check></icon-check>
					</button>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import router from '../router';
import InputPassword from '@/components/form/InputPassword.vue';
import IconCheck from '@/components/icons/IconCheck.vue';
import FormMessage from '@/components/FormMessage.vue';
import ApiError from '../api/error';

export default {
	data: () => ({
		form: {
			email: '',
			password: '',
		},
		errorCode: '',
		isLoading: false,
	}),
	components: {
		FormMessage,
		InputPassword,
		IconCheck,
	},
	setup: () => ({ v$: useVuelidate() }),
	validations: {
		form: {
			email: {
				required,
				email,
			},
			password: { required },
		},
	},
	methods: {
		async submitForm() {
			this.errorCode = '';
			const isFormCorrect = await this.v$.$validate();
			if (!isFormCorrect) {
				document.querySelectorAll('.form-group.error input')[0].focus();
				return;
			}
			try {
				this.isLoading = true;
				await this.$store.dispatch('authentication/register', this.form);
				this.isLoading = false;
				router.push({ name: 'Register Step 2' });
			} catch (error) {
				this.errorCode = error instanceof ApiError ? error.code : 500;
				this.isLoading = false;
			}
		},
	},
};
</script>
