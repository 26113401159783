<template>
  <p :style="card.img ? '--bg: url('+card.img+')' : null">
    <a :href="card.link" v-if="card.link">
			<span :data-number="card.number ? card.number : null">{{ card.text }}</span>
    </a>
    <span :data-number="card.number ? card.number : null" v-else>{{ card.text }}</span>
  </p>
</template>

<script>
export default {
	name: 'Card',
	props: [ 'card' ],
};
</script>

<style lang="scss" scoped>
	@use "./src/assets/scss/abstracts" as *;

	[data-number] {
		&::before {
			content: attr(data-number);
			display: block;
			font-family: $sans-serif-font-family;
			font-size: 2.315em;
		}
	}

	p {
		--p: var(--sp);
		--rad: #{rem(8px)};
		position: relative;
		overflow: hidden;
		width: 100%;
		height: 0;
		padding-bottom: 60%;
		border-radius: var(--rad);
		background-image: linear-gradient(to top, rgba(45, 25, 38, 0.5) 15%, transparent 60%), var(--bg, url('~@/assets/img/svg/placeholder.svg'));
		background-size: cover;
		background-color: var(--color-grey-3);
	}

	a,
	span {
		padding: var(--p);
		font-size: $fs-1;
		font-weight: $w-bold;
		font-family: $serif-font-family;
		color: #fff;
	}

	a {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		text-decoration: none;
		outline-offset: calc(var(--p) / -2);

		&:hover {
			outline-style: auto;
		}
	}

	span {
		display: block;
		position: absolute;
		left: var(--p);
		bottom: var(--p);
		right: var(--p);
		border-radius: var(--rad);
		backdrop-filter: blur(10px);
		background-color: rgba(#000, .25);
	}
</style>
