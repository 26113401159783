<template>
	<div class="intro">
		<h2 v-html="$t('slipAdd.intro')"></h2>
	</div>
	<div class="container mt-1.5">
		<SlipAdd v-if="user.passport"/>
		<Panel
			v-else
			:text="$t('slip.alert-add-passport.text')"
			link="/account-passport"
			:linkText="$t('slip.alert-add-passport.link')"
		/>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import SlipAdd from '../components/SlipAdd.vue';
import Panel from '../components/content/Panel.vue';

export default {
	components: {
		Panel,
		SlipAdd,
	},
	computed: {
		...mapState('authentication', [ 'user' ]),
	},
};
</script>

<style lang="scss" scoped>
@use "./src/assets/scss/abstracts" as *;

.intro {
	padding: var(--sp-1\.5) var(--sp);
	background-color: var(--color-grey-2);
}

h2 {
	font-family: $sans-serif-font-family;
	font-weight: $w-regular;

	:deep(span) {
		display: block;
		margin-bottom: var(--sp-0\.5);
		font-family: $serif-font-family;
		font-size: $fs-1;
		color: var(--color-secondary);
	}
}
</style>
