<template>
	<div class="wrap">
		<div class="container bg-white pt-1.5 pb-2">
			<div class="font-serif">{{ $t('home.hello') }}</div>
			<h2 class="h1 text-secondary mt-0.33">{{ $t('home.welcome-app') }}</h2>
			<p>{{ $t('home.get-refund') }}</p>
		</div>
		<div class="py-2 sliders">
				<h3 class="container fs-2 mb">{{ $t('reassurance.title') }}</h3>
			<Slider :card="slidesReassurances"></Slider>
				<h3 class="container mt-2 fs-2 mb">{{ $t('home.fast') }} : <br>{{ $t('steps.title') }}</h3>
			<Slider :card="slidesSteps"></Slider>
		</div>
	</div>
	<div class="cta">
		<router-link :to="{ name: user ? 'Welcome' : 'Login' }" class="btn">
			{{ $t('home.understand-connect') }}
		</router-link>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import Slider from '@/components/content/Slider.vue';

export default {
	name: 'Home',
	components: {
		Slider,
	},
	computed: {
		...mapState('authentication', [ 'user' ]),
		slidesReassurances() {
			return [
				{
					id: '1',
					text: this.$t('reassurance.title-1'),
					img: 'https://picsum.photos/375/460',
				},
				{
					id: '2',
					text: this.$t('reassurance.title-2'),
					img: 'https://picsum.photos/375/465',
				},
				{
					id: '3',
					text: this.$t('reassurance.title-3'),
					img: 'https://picsum.photos/375/470',
				},
			];
		},
		slidesSteps() {
			return [
				{
					id: '1',
					number: '1',
					text: this.$t('steps.title-1'),
					img: 'https://picsum.photos/375/475',
				},
				{
					id: '2',
					number: '2',
					text: this.$t('steps.title-2'),
					img: 'https://picsum.photos/375/480',
				},
				{
					id: '3',
					number: '3',
					text: this.$t('steps.title-3'),
					img: 'https://picsum.photos/375/485',
				},
				{
					id: '4',
					number: '4',
					text: this.$t('steps.title-4'),
					img: 'https://picsum.photos/375/490',
				},
			];
		},
	},
};
</script>

<style lang="scss" scoped>
	.wrap {
		min-height: var(--full-height);
		padding-bottom: var(--container-cta-login-height);
		background-color: var(--color-grey-1);
	}

	h2 {
		margin-top: 0;
	}

	.container {
		margin-bottom: 0;
	}

	.cta {
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		height: var(--container-cta-login-height);
	}
</style>
