<template>
	<div class="container">
		<div class="row">
			<div class="col-6">
				<h1>Operations</h1>
				<hr>
			</div>
			<div class="col-6">
				<Operation />
			</div>
		</div>
	</div>
</template>

<script>
import Operation from '@/components/Operation.vue';

export default {
	components: {
		Operation,
	},
};

</script>
