<template>
	<nav role="navigation" id="menu" aria-label="Menu de navigation principal">
		<ul>
			<li>
				<router-link :to="{ name: 'Welcome' }">
					<icon-logo class="dual"></icon-logo>
					{{ $t('nav.home')}}
				</router-link>
			</li>
			<li>
				<router-link :to="{ name: 'Slip' }">
					<div class="pastil-parent">
						<icon-file class="dual"></icon-file>
						<div class="pastil" aria-hidden="true" style="--t: 60%">{{ sortedSlipsByStatusPayableAndCustomsWaiting.length }}</div>
					</div>
					{{ $t('nav.slips')}}
				</router-link>
			</li>
			<li>
				<router-link :to="{ name: 'Slip Add' }" class="btn add" :aria-label="$t('nav.add-slip')">
					<icon-file-plus></icon-file-plus>
					{{ $t('nav.add')}}
				</router-link>
			</li>
			<li>
				<router-link :to="{ name: 'History' }">
					<icon-euro-refresh class="dual"></icon-euro-refresh>
					{{ $t('nav.history')}}
				</router-link>
			</li>
			<li>
				<router-link :to="{ name: 'Account' }">
					<icon-user class="dual"></icon-user>
					{{ $t('nav.account') }}
				</router-link>
			</li>
		</ul>
	</nav>
</template>

<script>
import { mapGetters } from 'vuex';
import router from '../../router';
import { IconEuroRefresh, IconFile, IconFilePlus, IconLogo, IconUser } from '@/components/icons';

export default {
	components: {
		IconEuroRefresh,
		IconFile,
		IconFilePlus,
		IconLogo,
		IconUser,
	},
	computed: {
		...mapGetters('slip', [ 'sortedSlipsByStatusPayableAndCustomsWaiting' ]),
	},
	methods: {
		handleClick() {
			router.push({ name: 'Slip Add' });
		},
	},
};
</script>

<style lang="scss" scoped>
	@use "./src/assets/scss/abstracts" as *;

	nav {
		z-index: var(--z-max);
		position: sticky;
		bottom: 0;
		display: flex;
		width: 100%;
		padding-top: rem(60px);
		pointer-events: none;
		background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0, #FFFFFF 60%);
	}

	ul {
		display: flex;
		width: 100%;
		pointer-events: auto;
		padding: 0;
		margin: auto 0 0;
		list-style: none;
	}

	li {
		width: calc(100% / 5);
		text-align: center;
	}

	a {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: rem(10px);
		font-weight: $w-semibold;

		svg {
			width: rem(32px);
			height: rem(22px);
			margin: 0 0 var(--sp-0\.75);
		}

		&:not(.add) {
			padding-top: var(--sp);
			padding-bottom: var(--sp);
			text-decoration: none;

			&::after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 50%;
				width: rem(27px);
				height: rem(3px);
				transform: translateX(-50%);
				background-color: var(--color-slip);
				opacity: var(--o, 0);
			}

			&[aria-current="page"] {
				--o: 1;
				font-weight: $w-bold;
				color: var(--color-secondary);
			}

			svg {
				color: var(--color-primary);
			}
		}
	}

	.add {
		display: inline-flex;
		margin-top: calc(var(--sp) * -1);
		padding: rem(15px) rem(12px);
		border: rem(2px) solid var(--color-primary);
		border-radius: 100em;

		&[aria-current="page"] {
			border-color: var(--color-grey-3);
			font-weight: $w-bold;
			color: var(--color-secondary);
			background-color: var(--color-grey-1);

			svg {
				color: var(--color-primary);
				fill: var(--color-secondary);
			}
		}
	}
</style>
