import axios from 'axios';

function dataURItoBlob(dataURI) {
	// convert base64 to raw binary data held in a string
	// doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
	const byteString = atob(dataURI.split(',')[1]);

	// separate out the mime component
	const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

	// write the bytes of the string to an ArrayBuffer
	const ab = new ArrayBuffer(byteString.length);

	// create a view into the buffer
	const ia = new Uint8Array(ab);

	// set the bytes of the buffer to the correct values
	for (let i = 0; i < byteString.length; ++i) {
		ia[i] = byteString.charCodeAt(i);
	}

	// write the ArrayBuffer to a blob, and you're done
	return new Blob([ ab ], { type: mimeString });
}

const MRZService = {
	async extractInfos(picture) {
		const image = picture instanceof Blob ? picture : dataURItoBlob(picture);
		const form = new FormData();
		form.append('attachment', image);
		const { data } = await axios({
			method: 'post',
			baseURL: process.env.VUE_APP_API_GCP_URL,
			url: '/cashparis-passport-mrz',
			headers: { 'Content-Type': 'multipart/form-data', authorization: 'NoHackerPLZ' }, // TODO generate auth from account ?
			data: form,
		});
		return data;
	},
};

export default MRZService;
