<template>
	<div>
		<FormMessage v-if="forgetPassswordSuccess" :message="$t('forget-password.success')" type="success" />
		<FormMessage v-if="errorCode" :message="$t('error.' + errorCode)" type="error" />
		<form @submit.prevent="submitForm">
			<div class="row align-center">
				<div class="col-6">
					<div class="form-group">
						<label for="userEmail">{{ $t('misc.your-email') }}</label>
						<input type="email" id="userEmail" v-model="email" autocomplete="username" required>
					</div>
				</div>
				<div class="col-6">
					<div class="form-group">
						<InputPassword v-model="password"></InputPassword>
					</div>
				</div>
				<div class="col-auto mt">
					<button type="button" class="btn btn--link fs-3" @click="showModal = true">
						<icon-question></icon-question>{{ $t('signin.forgotten-password') }}
					</button>
				</div>
				<div class="col-auto mt ml-a">
					<button type="submit" class="btn" :class="{ 'btn--loading': isLoading }">
						{{ $t('signin.validate') }}
						<icon-check></icon-check>
					</button>
				</div>
			</div>
		</form>
	</div>
	<ForgetPassword
		:showModal="showModal"
		@close="showModal = false"
		@success="forgetPassswordSuccess = true"
	/>
</template>

<script>
import router from '../router';
import InputPassword from '@/components/form/InputPassword.vue';
import IconCheck from '@/components/icons/IconCheck.vue';
import IconQuestion from '@/components/icons/IconQuestion.vue';
import ForgetPassword from '@/components/modal/ForgetPassword.vue';
import FormMessage from '@/components/FormMessage.vue';
import ApiError from '../api/error';

export default {
	data: () => ({
		email: '',
		password: '',
		showModal: false,
		errorCode: '',
		forgetPassswordSuccess: false,
		isLoading: false,
	}),
	components: {
		FormMessage,
		InputPassword,
		IconCheck,
		IconQuestion,
		ForgetPassword,
	},
	methods: {
		async submitForm() {
			try {
				this.errorCode = '';
				this.isLoading = true;
				await this.$store.dispatch('authentication/login', ({
					email: this.email,
					password: this.password,
				}));
				this.isLoading = false;
				router.push({ name: 'Welcome' });
			} catch (error) {
				this.errorCode = error instanceof ApiError ? error.code : 500;
				this.isLoading = false;
			}
		},
	},
};
</script>
