<template>
	<div class="content bg-grey-1">
		<div class="px py-1.5">
			<select-language-field/>
		</div>
	</div>
</template>

<script>
import SelectLanguageField from '@/components/SelectLanguageField.vue';

export default {
	name: 'AccountUpdateLanguage',
	components: { SelectLanguageField },
};
</script>

<style lang="scss" scoped>
	.content {
		min-height: var(--full-height);
	}
</style>
