<template>
	<div class="mt">

		<!-- Colors -->
		<stylesguide-colors></stylesguide-colors>
		<hr class="sep">

		<!-- Icons -->
		<stylesguide-icons></stylesguide-icons>
		<hr class="sep">

		<!-- Text -->
		<stylesguide-text></stylesguide-text>
		<hr class="sep">

		<!-- Buttons -->
		<stylesguide-button></stylesguide-button>
		<hr class="sep">

		<!-- Form -->
		<stylesguide-form></stylesguide-form>
		<hr class="sep">

		<!-- Pastil -->
		<stylesguide-pastil></stylesguide-pastil>
		<hr class="sep">

		<!-- Cards -->
		<stylesguide-card></stylesguide-card>
		<hr class="sep">

		<!-- Panels -->
		<stylesguide-panel></stylesguide-panel>
		<hr class="sep">

		<!-- Sliders -->
		<stylesguide-slider></stylesguide-slider>
		<hr class="sep">

		<!-- Slip cards -->
		<stylesguide-slip></stylesguide-slip>
		<hr class="sep">

		<!-- Operations -->
		<stylesguide-operation></stylesguide-operation>
		<hr class="sep">

	</div>

</template>

<script>
import {
	StylesguideColors,
	StylesguideIcons,
	StylesguideText,
	StylesguideForm,
	StylesguideButton,
	StylesguidePastil,
	StylesguideCard,
	StylesguidePanel,
	StylesguideSlider,
	StylesguideSlip,
	StylesguideOperation,
} from '@/components/stylesguide';

export default {
	components: {
		StylesguideColors,
		StylesguideIcons,
		StylesguideText,
		StylesguideForm,
		StylesguideButton,
		StylesguidePastil,
		StylesguideCard,
		StylesguidePanel,
		StylesguideSlider,
		StylesguideSlip,
		StylesguideOperation,
	},
};
</script>

<style lang="scss" scoped>
	@use "./src/assets/scss/abstracts" as *;

	.sep {
		margin: var(--sp-3) 0;
		border-width: rem(10px);
		border-color: var(--color-grey-2);
	}
</style>
