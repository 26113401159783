<template>
	<svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.71581 12.81145">
		<path d="M6.40674 12.81145a.7476.7476 0 0 1-.53028-.21973L.21973 6.93497a.74974.74974 0 0 1-.00027-1.06028l.00027-.00027L5.87646.2177A.74992.74992 0 0 1 6.937 1.27824L1.81055 6.40471 6.937 11.53117a.75.75 0 0 1-.53027 1.28028Z"/>
		<path d="M15.96582 7.13911h-14.5a.75.75 0 0 1 0-1.5h14.5a.75.75 0 0 1 0 1.5Z"/>
	</svg>
</template>

<style scoped>
	[dir="rtl"] svg {
		transform: scaleX(-1);
	}
</style>
