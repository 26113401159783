<template>
	<div class="content bg-grey-1">
		<div class="px py-1.5">
			<FormMessage v-if="success" :message="$t('account.connection-success')" type="success" />
			<FormMessage v-if="errorCode" :message="$t('error.' + errorCode)" type="error" />
			<form @submit.prevent="submitForm">
				<div class="row align-center">
					<div class="col-6">
						<div class="form-group" :class="{ 'error': v$.form.email.$error }">
							<label for="userEmailName">
								{{ $t('account.your-mail') }}
								<abbr aria-hidden="true" title="Champ obligatoire">*</abbr>
							</label>
							<input
								type="email"
								id="userEmailName"
								autocomplete="email"
								:aria-describedby="v$.form.email.$error ? 'userEmailError' : null" v-model="form.email"
								required
							>
							<span id="userEmailError" class="message error" v-if="v$.form.email.$error">
								<template v-if="v$.form.email.required.$invalid">{{ $t('misc.is-required-field') }}</template>
								<template v-if="v$.form.email.email.$invalid">{{ $t('misc.invalid-email') }}</template>
							</span>
						</div>
					</div>
					<div class="col-6">
						<div class="form-group" :class="{ 'error': v$.form.password.$error }">
							<InputPassword
								v-model="form.password"
								:label="$t('account.your-password')"
							/>
							<span id="userPwdError" class="message error" v-if="v$.form.password.$error">
								<template v-if="v$.form.password.required.$invalid">{{ $t('misc.is-required-field') }}</template>
						</span>
						</div>
					</div>
					<div class="col-6">
						<div class="form-group" :class="{ 'error': v$.form.newPassword.$error }">
							<InputPassword
								v-model="form.newPassword"
								:label="$t('account.new-password')"
								showPattern="true"
							/>
							<span id="userNewPwdError" class="message error" v-if="v$.form.newPassword.$error">
								<template v-if="v$.form.newPassword.required.$invalid">{{ $t('misc.is-required-field') }}</template>
							</span>
						</div>
					</div>
					<div class="col-6 text-right">
						<button type="submit" class="btn" :class="{ 'btn--loading': isLoading }">
							{{ $t('account.update-connection-information') }}
							<icon-check></icon-check>
						</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import IconCheck from '../../components/icons/IconCheck.vue';
import FormMessage from '../../components/FormMessage.vue';
import InputPassword from '../../components/form/InputPassword.vue';
import AuthenticationService from '../../api/authentication';

export default {
	components: {
		InputPassword,
		FormMessage,
		IconCheck,
	},
	data: () => ({
		form: {
			email: '',
			password: '',
			newPassword: '',
		},
		success: false,
		errorCode: '',
		isLoading: false,
	}),
	setup: () => ({ v$: useVuelidate() }),
	validations: {
		form: {
			email: {
				required,
				email,
			},
			password: { required },
			newPassword: { required },
		},
	},
	computed: {
		...mapState('authentication', [ 'user' ]),
	},
	methods: {
		async submitForm() {
			const isFormCorrect = await this.v$.$validate();
			if (!isFormCorrect) {
				return;
			}

			this.errorCode = '';
			this.success = false;
			this.isLoading = true;
			const response = await AuthenticationService.changePasssword(this.form);
			if (response.succeeded) {
				this.success = true;
				this.isLoading = false;
			} else {
				this.success = false;
				this.errorCode = response.code;
				this.isLoading = false;
			}
		},
	},
	mounted() {
		if (this.user) {
			this.form.email = this.user.email;
		}
	},
};
</script>

<style lang="scss" scoped>
.content {
	min-height: var(--full-height);
}
</style>
