/* eslint-disable no-unused-vars */
import _ from 'lodash';
import SlipService from '@/api/slip';
import ApiError from '../api/error';

export const SLIP_STATUS_CUSTOMS_WAITING = 0;
export const SLIP_STATUS_PAYABLE = 1;
export const SLIP_STATUS_CREDIT_CARD_BLOCK = 2;
export const SLIP_STATUS_CANCELED = 3;
export const SLIP_STATUS_PAID = 4;
export const SLIP_STATUS_RESERVED_BANK_CARD = 5;
export const SLIP_STATUS_OVER_1000 = 6;

export default {
	namespaced: true,
	state: () => ({
		slips: [],
	}),
	getters: {
		totalRefund: (state, getters) => {
			return _.round(getters.sortedSlipsByStatusPayableAndCustomsWaiting.reduce((a, slip) => a + slip.totalRefund, 0), 2);
		},
		sortedSlipsByStatusPaid: (state) => {
			const slips = _.filter(state.slips, { statusCode: SLIP_STATUS_PAID });
			return _.sortBy(slips, 'createdAt').reverse();
		},
		sortedSlipsByStatusPayableAndCustomsWaiting: (state) => {
			return _.filter(state.slips, (slip) => {
				const equalToStatusCode = _.partial(_.isEqual, slip.statusCode);
				return [ SLIP_STATUS_PAYABLE, SLIP_STATUS_CUSTOMS_WAITING ].some(equalToStatusCode);
			});
		},
	},
	mutations: {
		setSlips(state, slips) {
			state.slips = slips;
		},
		setSlip(state, slip) {
			state.slips = [ ...state.slips, slip ];
		},
	},
	actions: {
		async fetchAll({ commit }) {
			const response = await SlipService.fetchAll();
			if (!response.succeeded) {
				commit('setSlips', []);
				throw new ApiError(response.code);
			}

			commit('setSlips', response.data);
		},
		async fetch({ commit }, slipNumber) {
			const response = await SlipService.fetch(slipNumber);
			if (!response.succeeded) {
				throw new ApiError(response.code);
			}

			commit('setSlip', response.data);
			return response.data;
		},
		async add({ commit, dispatch }, slipNumber) {
			const response = await SlipService.add(slipNumber);
			if (!response.succeeded) {
				throw new ApiError(response.code);
			}

			return await dispatch('fetch', slipNumber);
		},
	},
};
