<template>
	<modal :showModal="showModal" @close="$emit('close')">
		<template v-slot:header>
			<span class="text-primary">{{ $t(`confirmSlipAdd.${statusCode}.title`) }}</span>
		</template>
		<template v-slot:body>
			<div class="text-center">
				<div v-if="success">
					<icon-check :class="`icon bg-success`"></icon-check>
					<p class="h1 text-success">{{ $t(`confirmSlipAdd.${statusCode}.message`) }}</p>
					<p>{{ $t(`confirmSlipAdd.${statusCode}.info`) }}</p>
				</div>
				<div v-else-if="warning">
					<icon-info class="icon bg-warning"></icon-info>
					<p class="h1 text-warning">{{ $t(`confirmSlipAdd.${statusCode}.message`) }}</p>
					<p>{{ $t(`confirmSlipAdd.${statusCode}.info`) }}</p>
				</div>
				<div v-else>
					<icon-bell class="icon bg-secondary"></icon-bell>
					<p class="h1 text-secondary">{{ $t(`confirmSlipAdd.${statusCode}.message`) }}</p>
					<p>{{ $t(`confirmSlipAdd.${statusCode}.info`) }}</p>
				</div>
			</div>
			<div class="row align-center mt-2">
				<div class="col-auto mx-a">
					<button @click="$emit('close')" class="btn btn--link fs-3">
						{{ $t('confirmSlipAdd.retry') }}
					</button>
				</div>
				<div class="col-auto mx-a">
					<router-link :to="{ name: 'Welcome' }" class="btn">
						{{ $t('confirmSlipAdd.finish') }}
					</router-link>
				</div>
			</div>
		</template>
	</modal>
</template>

<script>
import Modal from './Modal.vue';
import { IconCheck, IconBell, IconInfo } from '@/components/icons';
import {
	SLIP_STATUS_PAYABLE,
	SLIP_STATUS_CUSTOMS_WAITING,
	SLIP_STATUS_CANCELED,
	SLIP_STATUS_PAID,
	SLIP_STATUS_RESERVED_BANK_CARD,
	SLIP_STATUS_CREDIT_CARD_BLOCK,
	SLIP_STATUS_OVER_1000,
} from '@/store/slip';

export default {
	components: {
		Modal,
		IconCheck,
		IconBell,
		IconInfo,
	},
	computed: {
		success() {
			return [ SLIP_STATUS_PAYABLE, SLIP_STATUS_CUSTOMS_WAITING, SLIP_STATUS_PAID ].includes(this.statusCode);
		},
		warning() {
			return [ SLIP_STATUS_CANCELED, SLIP_STATUS_CREDIT_CARD_BLOCK, SLIP_STATUS_RESERVED_BANK_CARD, SLIP_STATUS_OVER_1000 ].includes(this.statusCode);
		},
	},
	props: [ 'showModal', 'statusCode' ],
};
</script>

<style lang="scss" scoped>
	@use "./src/assets/scss/abstracts" as *;

	.icon {
		width: 64px;
		height: 64px;
		padding: var(--sp-0\.75);
		color: var(--color-white);
		border-radius: 50%;
	}
</style>
