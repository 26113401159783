<template>
	<svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.15723 12.81348">
		<path d="M.00003 6.40674a.75029.75029 0 0 0 .2197.53032l5.65722 5.65669A.7499221.7499221 0 1 0 6.9375 11.5332l-5.127-5.12646 5.127-5.12647a.74973.74973 0 0 0 0-1.06055.74975.74975 0 0 0-1.06055 0L.21973 5.87646a.75031.75031 0 0 0-.2197.53028Z"/>
	</svg>
</template>

<style scoped>
	[dir="rtl"] svg {
		transform: scaleX(-1);
	}
</style>
