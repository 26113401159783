<template>
	<svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.5 26.85072">
		<path fill="currentColor" d="M16.75 26.85072h-16a.7502.7502 0 0 1-.75-.75v-22a.7502.7502 0 0 1 .75-.75h16a2.7528 2.7528 0 0 1 2.75 2.75v18a2.7528 2.7528 0 0 1-2.75 2.75Zm-15.25-1.5h15.25a1.25116 1.25116 0 0 0 1.25-1.25v-18a1.25116 1.25116 0 0 0-1.25-1.25H1.5Z"/>
	<g fill="currentColor">
		<path d="M12.2006.82913.75 4.10072h14V2.75218A2 2 0 0 0 12.2006.82913Z"/>
		<path d="M14.75 4.85072h-14a.75.75 0 0 1-.206-1.47119L11.99414.10804A2.74993 2.74993 0 0 1 15.5 2.75209v1.34863a.7502.7502 0 0 1-.75.75Zm-8.645-1.5H14v-.59863a1.24952 1.24952 0 0 0-1.59375-1.20166Z"/>
	</g>
		<path d="M14.25 14.10072h-9a.5.5 0 0 1 0-1h9a.5.5 0 0 1 0 1Z"/>
		<path d="M9.75 19.60072a.49971.49971 0 0 1-.5-.5v-10a.5.5 0 0 1 1 0v10a.49971.49971 0 0 1-.5.5Z"/>
		<path d="M9.75 19.85072a6.25 6.25 0 1 1 6.25-6.25 6.257 6.257 0 0 1-6.25 6.25Zm0-11a4.75 4.75 0 1 0 4.75 4.75 4.7554 4.7554 0 0 0-4.75-4.75Z"/>
		<path d="M9.75 19.60072c-1.71045 0-3-2.5791-3-6 0-3.42041 1.28955-6 3-6 1.71 0 3 2.57959 3 6 0 3.4209-1.29 6-3 6Zm0-11c-.94434 0-2 2.13818-2 5 0 2.86133 1.05566 5 2 5s2-2.13867 2-5c0-2.86182-1.05566-5-2-5Z"/>
	</svg>
</template>
