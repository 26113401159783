import { createStore } from 'vuex';

import authenticationStore from './authentication';
import slipStore from './slip';

const store = createStore({
	state: {
	},
	mutations: {
	},
	actions: {
	},
	modules: {
		authentication: authenticationStore,
		slip: slipStore,
	},
});

store.watch((state) => state.authentication.user, () => {
	store.dispatch('slip/fetchAll').catch(() => null);
});

export default store;
