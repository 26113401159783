<template>
	<div class="container">
		<div class="row">
			<div class="col-6">
				<h1>Form</h1>
				<hr>
			</div>
			<div class="col-6">
				<div class="form-group">
					<label for="text1">Textual input</label>
					<input type="text" id="text1" aria-describedby="text1Message">
					<span class="message" id="text1Message">Lorem ipsum, dolor sit amet consectetur adipisicing elit.</span>
				</div>
			</div>
			<div class="col-6">
				<div class="form-group">
					<label for="text1Add">Textual input with a button</label>
					<div class="add">
						<input type="text" id="text1Add">
						<button type="button" class="btn btn--ghost">
							<icon-crossed-eye></icon-crossed-eye>
						</button>
					</div>
				</div>
			</div>
			<div class="col-6">
				<div class="form-group">
					<label for="text2Add">Textual input with an icon</label>
					<div class="add">
						<icon-locked class="dual"></icon-locked>
						<input type="text" id="text2Add">
					</div>
				</div>
			</div>
			<div class="col-6">
				<div class="form-group">
					<label for="text3Add">Textual input with both</label>
					<div class="add">
						<icon-locked class="dual"></icon-locked>
						<input type="text" id="text3Add">
						<button type="button" class="btn btn--ghost">
							<icon-crossed-eye></icon-crossed-eye>
						</button>
					</div>
				</div>
			</div>
			<div class="col-6">
				<div class="form-group">
					<label for="text2">Disabled input</label>
					<input type="text" id="text2" disabled value="I'm disabled">
				</div>
			</div>
			<div class="col-6">
				<div class="form-group">
					<label for="select1">Select</label>
					<select id="select1">
						<option disabled selected>Choose an option</option>
						<optgroup label="Europe">
							<option>France</option>
							<option>England</option>
							<option>Germany</option>
						</optgroup>
						<optgroup label="Asia">
							<option>Japan</option>
							<option>China</option>
						</optgroup>
					</select>
				</div>
			</div>
			<div class="col-6">
				<div class="form-group">
					<label for="select1Add">Select with an icon</label>
					<div class="add">
						<icon-talk class="dual"></icon-talk>
						<select id="select1Add">
							<option disabled selected>Choose an option</option>
							<optgroup label="Europe">
								<option>France</option>
								<option>England</option>
								<option>Germany</option>
							</optgroup>
							<optgroup label="Asia">
								<option>Japan</option>
								<option>China</option>
							</optgroup>
						</select>
					</div>
				</div>
			</div>
			<div class="col-6">
				<div class="form-group row form-options" role="group" aria-labelledby="titleGroup1">
					<div class="label" id="titleGroup1">Checkboxes</div>
					<div class="col-3">
						<input type="checkbox" id="group1C1">
						<label for="group1C1">Checkbox</label>
					</div>
					<div class="col-3">
						<input type="checkbox" id="group1C2">
						<label for="group1C2">Checkbox</label>
					</div>
					<div class="col-3">
						<input type="checkbox" id="group1C3" disabled>
						<label for="group1C3">Checkbox</label>
					</div>
					<div class="col-3">
						<input type="checkbox" id="group1C4" checked disabled>
						<label for="group1C4">Checkbox</label>
					</div>
				</div>
			</div>
			<div class="col-6">
				<div class="form-group row form-options" role="group" aria-labelledby="titleGroup2">
					<div class="label" id="titleGroup2">Radios</div>
					<div class="col-3">
						<input type="radio" id="group2R1" name="group2R">
						<label for="group2R1">Radio</label>
					</div>
					<div class="col-3">
						<input type="radio" id="group2R2" name="group2R">
						<label for="group2R2">Radio</label>
					</div>
					<div class="col-3">
						<input type="radio" id="group2R3" name="group2R" disabled>
						<label for="group2R3">Radio</label>
					</div>
					<div class="col-3">
						<input type="radio" id="group2R4" name="group2R" checked disabled>
						<label for="group2R4">Radio</label>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { IconCrossedEye, IconLocked, IconTalk } from '@/components/icons';

export default {
	components: {
		IconCrossedEye,
		IconLocked,
		IconTalk,
	},
};
</script>
