<template>
	<div class="container pt-1.5">
		<div v-if="sortedSlipsByStatusPayableAndCustomsWaiting.length">
			<h2 class="fs-1">
				{{ sortedSlipsByStatusPayableAndCustomsWaiting.length }}
				<template v-if="sortedSlipsByStatusPayableAndCustomsWaiting.length > 1">{{ $t('slip.slips') }}</template>
				<template v-else>{{ $t('slip.slip') }}</template>
			</h2>
			<SlipCard v-for="slip in sortedSlipsByStatusPayableAndCustomsWaiting" :key="slip.number" :slip="slip" class="mb" />
			<p class="row align-center my-2">
				<span class="col font-serif text-right">
					{{ $t('slip.total') }}
				</span>
				<span class="col-auto fs-1 fw-bold font-sans-serif ml-a">
					{{ totalRefund }} €
				</span>
			</p>
		</div>
		<div v-else>
			<Panel
				:text="$t('slip.alert-add-passport.text')"
				link="/account-passport"
				:linkText="$t('slip.alert-add-passport.link')"
				class="mb-2"
				v-if="!user.passport"
			/>
			<p>
				<span>{{ $t('slip.no-operation') }}</span>
			</p>
			<h2 class="my-2 text-secondary">{{ $t('slip.start-operation') }} :</h2>
			<div class="row">
				<div class="col-auto fw-bold" aria-hidden="true">·</div>
				<div class="col">
					<p v-html="$t('slip.touch-button-add')"></p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import SlipCard from '@/components/SlipCard.vue';
import Panel from '@/components/content/Panel.vue';

export default {
	components: {
		SlipCard,
		Panel,
	},
	computed: {
		...mapState('authentication', [ 'user' ]),
		...mapGetters('slip', [ 'totalRefund', 'sortedSlipsByStatusPayableAndCustomsWaiting' ]),
	},
	mounted() {
		this.$store.dispatch('slip/fetchAll');
	},
};
</script>
