<template>
	<div class="container">
		<div class="row">
			<div class="col-6">
				<h1>Icons</h1>
				<hr>
			</div>
			<div class="col-6 icons">
				<h2>Single color</h2>
				<icon-arrow-down></icon-arrow-down>
				<icon-arrow-left></icon-arrow-left>
				<icon-arrow-right></icon-arrow-right>
				<icon-arrow-up></icon-arrow-up>
				<icon-bell></icon-bell>
				<icon-calendar></icon-calendar>
				<icon-check></icon-check>
				<icon-chevron-down></icon-chevron-down>
				<icon-chevron-left></icon-chevron-left>
				<icon-chevron-right></icon-chevron-right>
				<icon-chevron-up></icon-chevron-up>
				<icon-crossed-eye></icon-crossed-eye>
				<icon-dots></icon-dots>
				<icon-envelop></icon-envelop>
				<icon-euro></icon-euro>
				<icon-euro-circle></icon-euro-circle>
				<icon-euro-circle-full></icon-euro-circle-full>
				<icon-euro-refresh></icon-euro-refresh>
				<icon-file></icon-file>
				<icon-file-plus></icon-file-plus>
				<icon-file-refresh></icon-file-refresh>
				<icon-folder></icon-folder>
				<icon-fullscreen></icon-fullscreen>
				<icon-info></icon-info>
				<icon-locked></icon-locked>
				<icon-logo></icon-logo>
				<icon-passport></icon-passport>
				<icon-pen-square></icon-pen-square>
				<icon-power></icon-power>
				<icon-question></icon-question>
				<icon-save></icon-save>
				<icon-talk></icon-talk>
				<icon-trash></icon-trash>
				<icon-upload></icon-upload>
				<icon-user></icon-user>
				<icon-warning></icon-warning>
				<icon-x></icon-x>
			</div>
			<div class="col-6 icons">
				<h2>Dual color</h2>
				<icon-arrow-down class="hidden"></icon-arrow-down>
				<icon-arrow-left class="hidden"></icon-arrow-left>
				<icon-arrow-right class="hidden"></icon-arrow-right>
				<icon-arrow-up class="hidden"></icon-arrow-up>
				<icon-bell class="dual"></icon-bell>
				<icon-calendar class="dual"></icon-calendar>
				<icon-check class="hidden"></icon-check>
				<icon-chevron-down class="hidden"></icon-chevron-down>
				<icon-chevron-left class="hidden"></icon-chevron-left>
				<icon-chevron-right class="hidden"></icon-chevron-right>
				<icon-chevron-up class="hidden"></icon-chevron-up>
				<icon-crossed-eye class="hidden"></icon-crossed-eye>
				<icon-dots class="hidden"></icon-dots>
				<icon-envelop class="dual"></icon-envelop>
				<icon-euro class="hidden"></icon-euro>
				<icon-euro-circle class="dual"></icon-euro-circle>
				<icon-euro-circle-full class="dual"></icon-euro-circle-full>
				<icon-euro-refresh class="dual"></icon-euro-refresh>
				<icon-file class="dual"></icon-file>
				<icon-file-plus class="dual"></icon-file-plus>
				<icon-file-refresh class="dual"></icon-file-refresh>
				<icon-folder class="dual"></icon-folder>
				<icon-fullscreen class="hidden"></icon-fullscreen>
				<icon-info class="dual"></icon-info>
				<icon-locked class="dual"></icon-locked>
				<icon-logo class="dual"></icon-logo>
				<icon-passport class="dual"></icon-passport>
				<icon-pen-square class="dual"></icon-pen-square>
				<icon-power class="dual"></icon-power>
				<icon-question class="dual"></icon-question>
				<icon-save class="dual"></icon-save>
				<icon-talk class="dual"></icon-talk>
				<icon-trash class="dual"></icon-trash>
				<icon-upload class="dual"></icon-upload>
				<icon-user class="dual"></icon-user>
				<icon-warning class="dual"></icon-warning>
				<icon-x class="hidden"></icon-x>
			</div>
		</div>
	</div>
</template>

<script>
import {
	IconArrowDown,
	IconArrowLeft,
	IconArrowRight,
	IconArrowUp,
	IconBell,
	IconCalendar,
	IconCheck,
	IconChevronDown,
	IconChevronLeft,
	IconChevronRight,
	IconChevronUp,
	IconCrossedEye,
	IconDots,
	IconEnvelop,
	IconEuro,
	IconEuroCircle,
	IconEuroCircleFull,
	IconEuroRefresh,
	IconFile,
	IconFilePlus,
	IconFileRefresh,
	IconFolder,
	IconFullscreen,
	IconInfo,
	IconLocked,
	IconLogo,
	IconPassport,
	IconPenSquare,
	IconPower,
	IconQuestion,
	IconSave,
	IconTalk,
	IconTrash,
	IconUpload,
	IconUser,
	IconWarning,
	IconX,
}
	from '@/components/icons';

export default {
	components: {
		IconArrowDown,
		IconArrowLeft,
		IconArrowRight,
		IconArrowUp,
		IconBell,
		IconCalendar,
		IconCheck,
		IconChevronDown,
		IconChevronLeft,
		IconChevronRight,
		IconChevronUp,
		IconCrossedEye,
		IconDots,
		IconEnvelop,
		IconEuro,
		IconEuroCircle,
		IconEuroCircleFull,
		IconEuroRefresh,
		IconFile,
		IconFilePlus,
		IconFileRefresh,
		IconFolder,
		IconFullscreen,
		IconInfo,
		IconLocked,
		IconLogo,
		IconPassport,
		IconPenSquare,
		IconPower,
		IconQuestion,
		IconSave,
		IconTalk,
		IconTrash,
		IconUpload,
		IconUser,
		IconWarning,
		IconX,
	},
};
</script>

<style lang="scss" scoped>
	@use "./src/assets/scss/abstracts" as *;

	.icons {
		display: flex;
		flex-wrap: wrap;
		gap: var(--sp);

		h2 {
			margin: 0;
			width: 100%;
		}

		svg {
			width: 2.5rem;
			height: 2.5rem;

			&.hidden {
				opacity: .05;
				cursor: not-allowed;
			}
		}
	}
</style>
