<template>
	<modal :showModal="showModal" @close="$emit('close')">
		<template v-slot:header>
			{{ $t('barcode.scan.title') }}
		</template>
		<template v-slot:body>
			<div class="wrap">
				<video ref="camera" id="barcode-stream" autoplay playsinline muted></video>
			</div>
		</template>
	</modal>
</template>

<script>
import Modal from './Modal.vue';

// eslint-disable-next-line no-undef
const codeReader = new ZXingBrowser.BrowserMultiFormatReader();

export default {
	components: {
		Modal,
	},
	props: [ 'showModal' ],
	emits: [ 'read', 'scanError', 'close' ],
	data: () => ({
		controls: null,
	}),
	computed: {
	},
	methods: {
		stop() {
			if (this.controls) {
				this.controls.stop();
				this.controls = null;
			}
		},
		async start() {
			const constraints = {
				video: {
					facingMode: 'environment',
					width: { max: window.innerWidth },
					height: { max: window.innerHeight },
					// eslint-disable-next-line no-restricted-globals
					aspectRatio: { exact: 1.777 },
				},
			};
			this.controls = await codeReader.decodeFromConstraints(constraints, 'barcode-stream', (result) => {
				if (result) {
					this.$emit('read', result.text);
					this.stop();
				}
			});
		},
	},
	unmounted() {
		this.stop();
	},
	watch: {
		showModal() {
			if (this.showModal) {
				this.start();
			} else {
				this.stop();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	.wrap {
		position: relative;
		display: flex;

		video {
			width: 100%;
		}

		canvas {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	.scan {
		position: absolute;
		bottom: var(--sp);
		right: var(--sp);
	}
</style>
