<template>
	<div>
		<FormMessage v-if="errorCode" :message="$t('error.' + errorCode)" type="error" />
		<FormMessage v-if="scanError" :message="$t('passport.scan.error')" type="error" />
		<p class="h1 text-success text-center">{{ $t('register.success') }}</p>
		<Panel :text="$t('register.skip-explanations')" link="/welcome" :linkText="$t('register.skip')"></Panel>
		<form @submit.prevent="submitForm">
			<div class="row">
				<div class="col-6">
					<div class="form-group" :class="{ 'error': v$.form.passportNumber.$error }">
						<label for="userPassportNumber">{{ $t('register.passport') }}</label>
						<InputPassportScanner
							id="userPassportNumber"
							:aria-describedby="v$.form.passportNumber.$error ? 'userPassportNumberError' : null"
							v-model="form.passportNumber"
							@read="read"
							@scanError="scanError = true"
						/>
						<span id="userPassportNumberError" class="message error" v-if="v$.form.passportNumber.$error">
							<template v-if="v$.form.passportNumber.required.$invalid">{{ $t('misc.is-required-field') }}</template>
						</span>
					</div>
				</div>
				<div class="col-6">
					<div class="form-group" :class="{ 'error': v$.form.countryIso.$error }">
						<label for="userCountryIso">{{ $t('register.nationality') }}</label>
						<select
							v-model="form.countryIso"
							id="userCountryIso"
							:aria-describedby="v$.form.countryIso.$error ? 'userCountryIsoError' : null"
							required
						>
							<option
								v-for="nationality in nationalities"
								:key="nationality.numeric"
								:value="parseInt(nationality.numeric)"
							>
								{{ nationality.name }}
							</option>
						</select>
						<span id="userCountryIsoError" class="message error" v-if="v$.form.countryIso.$error">
							<template v-if="v$.form.countryIso.required.$invalid">{{ $t('misc.is-required-field') }}</template>
						</span>
					</div>
				</div>
				<div class="col-6 text-right">
					<button type="submit" class="btn" :class="{ 'btn--loading': isLoading }">
						{{ $t('register.validate') }}
						<icon-check></icon-check>
					</button>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import _ from 'lodash';
import router from '../router';
import { IconCheck } from '@/components/icons';
import Panel from '@/components/content/Panel.vue';
import FormMessage from '@/components/FormMessage.vue';
import PassportService from '../api/passport';
import InputPassportScanner from '@/components/form/InputPassportScanner.vue';
import nationalities from '@/lang/nationalities.json';

export default {
	components: {
		FormMessage,
		IconCheck,
		Panel,
		InputPassportScanner,
	},
	data: () => ({
		form: {
			passportNumber: '',
			countryIso: '',
		},
		errorCode: '',
		scanError: false,
		nationalities,
		isLoading: false,
	}),
	setup: () => ({ v$: useVuelidate() }),
	validations: {
		form: {
			passportNumber: {	required },
			countryIso: { required },
		},
	},
	methods: {
		read(passport) {
			this.form.passportNumber = passport.documentNumber;
			this.form.countryIso = this.convertNationalityToNumericCode(passport.nationality);
			this.scanError = false;
		},
		convertNationalityToNumericCode(alpha3Code) {
			const nationality = _.find(this.nationalities, { alpha3Code });
			return (nationality) ? nationality.numeric : 0;
		},
		async submitForm() {
			const isFormCorrect = await this.v$.$validate();
			if (!isFormCorrect) {
				return;
			}

			this.errorCode = '';
			this.isLoading = true;
			const response = await PassportService.add(this.form);
			if (response.succeeded) {
				this.$store.commit('authentication/setPassport', {
					number: this.form.passportNumber,
					iso: this.form.countryIso,
				});
				this.isLoading = false;
				router.push({ name: 'Welcome' });
			} else {
				this.errorCode = response.code;
				this.isLoading = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	@use "./src/assets/scss/abstracts" as *;

	.intro {
		padding: var(--sp-1\.5) var(--sp);
		background-color: var(--color-grey-2);
	}

	h2 {
		font-family: $sans-serif-font-family;

		span {
			display: block;
			font-weight: $w-regular;

			&:last-child {
				margin-top: var(--sp-0\.5);
				color: var(--color-secondary);
			}
		}
	}
</style>
