<template>
	<Slider fullscreen="true" :slides="slides"></Slider>
	<div class="cta">
		<router-link :to="{ name: user ? 'Welcome' : 'Login' }" class="btn">
			{{ $t( 'home.understand-connect' ) }}
		</router-link>
	</div>
</template>

<script>
import Slider from '@/components/content/Slider.vue';

export default {
	components: {
		Slider,
	},

	computed: {
		slides() {
			return [
				{
					id: '1',
					title: this.$t('reassurance.title-1'),
					text: this.$t('reassurance.text-1'),
					img: 'https://picsum.photos/375/460',
				},
				{
					id: '2',
					title: this.$t('reassurance.title-2'),
					text: this.$t('reassurance.text-2'),
					img: 'https://picsum.photos/375/465',
				},
				{
					id: '3',
					title: this.$t('reassurance.title-3'),
					text: this.$t('reassurance.text-3'),
					img: 'https://picsum.photos/375/470',
				},
			];
		},
	},
};
</script>

<style lang="scss" scoped>
	@use "./src/assets/scss/abstracts" as *;

	.cta {
		display: flex;
		align-items: center;
		justify-content: center;
		height: var(--container-cta-login-height);
		background-color: var(--color-white);
	}
</style>
