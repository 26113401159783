<template>
	<svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.15723 12.81348">
		<path d="M7.15723 6.40674a.75036.75036 0 0 0-.21973-.53028L1.28027.21972a.74973.74973 0 0 0-1.06054 0 .74973.74973 0 0 0 0 1.06055l5.12695 5.12647L.21973 11.5332a.74992.74992 0 1 0 1.06054 1.06055L6.9375 6.93706a.75033.75033 0 0 0 .21973-.53032Z"/>
	</svg>
</template>

<style scoped>
	[dir="rtl"] svg {
		transform: scaleX(-1);
	}
</style>
