import axios from 'axios';
import store from '@/store';
import router from '../router';

export default function initAxios() {
	axios.defaults.baseURL = `${process.env.VUE_APP_API_URL}`;
	axios.defaults.timeout = 5000;

	axios.interceptors.request.use(async (config) => {
		if (config.baseURL.startsWith(process.env.VUE_APP_API_URL)) {
			const { accessToken } = store.state.authentication;
			return {
				...config,
				headers: {
					...(accessToken && { Authorization: `token ${accessToken}` }),
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
			};
		}
		return config;
	});

	axios.interceptors.response.use(
		(response) => response,
		async (error) => {
			if (error.response.status === 401) {
				store.commit('authentication/clearLoginContext');
				router.push({
					name: 'Login',
				});

				throw error;
			}
		},
	);
}
