<template>
  <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 17.52818">
    <defs>
      <clipPath id="a" transform="translate(0 -.48853)">
        <path fill="none" d="M0 .5h32v17.5166H0z"/>
      </clipPath>
    </defs>
    <g clip-path="url(#a)">
      <path fill="currentColor" d="M11.7019 14.01147a21.21918 21.21918 0 0 1-1.1143 2.987 16.31 16.31 0 0 1-3.59484.5297C2.13025 17.52817 0 14.16777 0 11.06217c0-4.10423 3.16353-6.81913 7.52533-6.81913a15.91316 15.91316 0 0 1 3.47327.52676c.1562 1.11433.272 1.89581.3907 2.95225l-.5789.136a3.39589 3.39589 0 0 0-3.78869-2.91169c-3.26194 0-5.00145 2.75543-5.00145 5.725 0 3.6527 2.15051 6.1534 5.12012 6.1534 1.99131 0 3.04482-1.0767 4.02312-3.0275Z"/>
      <path d="m21.178 17.39507-.0898-.1245a17.87955 17.87955 0 0 1-1.7366-2.8075 20.55451 20.55451 0 0 1-1.1577-2.6541 13.52733 13.52733 0 0 1-.4631-2.0058c-.1129-.70042-.2373-1.40375-.3329-2.10708-.0781-.56151-.1302-1.1259-.1794-1.68741q-.09555-1.05354-.1621-2.11c-.0318-.48625-.0405-.9754-.055-1.46165a.35043.35043 0 0 1 .191-.28944.31533.31533 0 0 0 .1245-.36758h-.3618v-.41677a.36171.36171 0 0 0-.2894-.39363c-.0753-.02026-.0811-.05789-.0811-.11867V0h-.1534v.686c0 .14471.0174.27207-.1794.31838a.28923.28923 0 0 0-.1939.31838v.43994h-.3676v.23154l.0347.06079c.081.08394.165.16208.2431.25181l.0435.11c-.0406.8162-.0724 1.63241-.1216 2.44862-.0405.59624-.0839 1.19247-.1592 1.78581q-.1881 1.46744-.4197 2.93195c-.0926.5789-.2084 1.1404-.3444 1.7019a12.78656 12.78656 0 0 1-.8973 2.33 19.623 19.623 0 0 1-2.1765 3.6787l-.0492.0695h1.398a.14488.14488 0 0 0 .1447-.084c.2026-.3444.4225-.6801.6049-1.0361a2.158 2.158 0 0 1 .5789-.7034 3.28185 3.28185 0 0 1 1.5224-.7004 2.9578 2.9578 0 0 1 2.7265 1.0796 1.52286 1.52286 0 0 1 .1042.1592c.2315.3994.466.7988.6946 1.1982a.15653.15653 0 0 0 .1534.0869h1.2909Zm-6.1158-3.5137.0289-.1013a15.159 15.159 0 0 0 .6802-2.2432c.0145-.0579.0405-.0723.0955-.0723h1.2793a.07819.07819 0 0 1 .0337.001.0783.0783 0 0 1 .0511.0415.08065.08065 0 0 1 .0079.0327 14.91079 14.91079 0 0 0 .6743 2.2229l.0377.1187Zm1.5687-7.0999c.139 1.25905.2721 2.50651.4081 3.7627h-1.068c.1361-1.2475.2721-2.495.4081-3.7627Z"/>
      <path fill="currentColor" d="M27.3748 4.56433a5.51009 5.51009 0 0 1 3.3372.83357A3.2771 3.2771 0 0 1 32 8.19964a4.07837 4.07837 0 0 1-3.8697 4.06943 6.5432 6.5432 0 0 1-.8944 0l-1.4703-.3762v3.1172c0 1.6874.1794 1.7858 1.9653 1.9045v.5557h-5.3806v-.5557c1.589-.1187 1.7366-.2895 1.7366-1.9248V7.04769c0-1.73661-.1592-1.82634-1.6296-1.92764v-.55572Zm-1.6092 6.67154a4.26261 4.26261 0 0 0 1.4472.2373c1.2908 0 2.8799-.6947 2.8799-3.21564a2.78714 2.78714 0 0 0-2.9986-3.05644 1.858 1.858 0 0 0-1.1114.23734c-.139.13893-.22.37626-.22.932Z"/>
    </g>
  </svg>
</template>
