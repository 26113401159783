<template>
	<p tabindex="-1" ref="message" :class="type">{{ message }}</p>
</template>

<script>
export default {
	props: [ 'message', 'type' ],
	mounted() {
		this.$refs.message.focus();
	},
};
</script>

<style lang="scss" scoped>
	p {
		padding: .5em 1em;
		border-left: 0.25rem solid currentColor;
		background-color: var(--color-white);
		box-shadow: 0 0 0 1px var(--color-grey-1);

		&:focus {
			outline-offset: 0.25rem;
		}
	}

	.error { color: var(--color-secondary) }
	.warning { color: var(--color-warning) }
	.success { color: var(--color-success) }
</style>
