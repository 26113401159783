<template>
	<div class="container">
		<div class="row">
			<div class="col-6">
				<h1>Text</h1>
				<hr>
			</div>
			<div class="col-6">
				<h1>Title h1</h1>
				<h2>Title h2</h2>
				<h3>Title h3</h3>
				<p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Distinctio omnis in adipisci reiciendis laboriosam iure, dolor labore dolore, quam asperiores iste minima obcaecati aut totam nam, doloribus eius, quas quaerat?</p>
				<p class="font-serif">Soluta sequi consequatur voluptate. Quaerat aut omnis eius, laudantium, accusamus quis natus laboriosam molestias! Qui inventore fugiat saepe architecto aperiam dolorem quibusdam? Sapiente ex fugiat error atque dolorem ipsa qui ducimus porro, quos laborum.</p>
			</div>
			<div class="col-6">
				<a href="#">How does it works ?</a>
			</div>
			<div class="col-6">
				<a href="#">
					<icon-question></icon-question>How does it works ?
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import { IconQuestion } from '@/components/icons';

export default {
	components: {
		IconQuestion,
	},
};
</script>
