import AuthenticationService from '../api/authentication';

export default {
	async signin(email, password) {
		return await AuthenticationService.login(email, password);
	},
	async signup(email, password) {
		return await AuthenticationService.register(email, password);
	},
	async me() {
		return await AuthenticationService.fetchUser();
	},
};
