<template>
	<div>
		<form @submit.prevent="submitForm">
			<h2>{{ $t('slipAdd.title-way-type') }}</h2>
			<div class="form-group" :class="{ 'error': v$.slipNumber.$error }">
				<label for="slipNumber">{{ $t( 'slipAdd.number' ) }}</label>
				<div class="add">
					<input type="text" id="slipNumber" ref="slipNumber"  aria-describedby="slipNumberPattern slipNumberError" v-model="slipNumber" minlength="20" maxlength="20" inputmode="numeric" required>
					<button type="submit" class="btn" :title="$t('slipAdd.save')" :class="{ 'btn--loading': isLoading }">
						<icon-save></icon-save>
					</button>
				</div>
				<span id="slipNumberError" class="message error" v-if="v$.slipNumber.$error">
					<template v-if="v$.slipNumber.required.$invalid">{{ $t('misc.is-required-field') }}&nbsp;</template>
					<template v-if="v$.slipNumber.numeric.$invalid">{{ $t('slipAdd.numbers-only') }}&nbsp;</template>
					<template v-if="v$.slipNumber.minLength.$invalid">{{ $t('slipAdd.min-error') }}&nbsp;</template>
					<template v-if="v$.slipNumber.maxLength.$invalid">{{ $t('slipAdd.max-error') }}&nbsp;</template>
				</span>
				<span id="slipNumberPattern" class="message">{{ $t('slipAdd.pattern') }}</span>
			</div>
		</form>
		<div aria-hidden="true" class="or">{{ $t('misc.or') }}</div>
		<form @submit.prevent="submitPDF">
			<h2>{{ $t('slipAdd.title-way-upload') }}</h2>
			<div class="form-group">
				<label for="slipNumber">{{ $t( 'slipAdd.upload-label' ) }}</label>
				<div class="add">
					<input type="file" ref="pdfInput" id="slipUpload" aria-describedby="slipUploadPattern slipUploadError" accept="application/pdf,image/*" required>
					<button type="submit" class="btn" :title="$t('slipAdd.save')" :class="{ 'btn--loading': isLoading }">
						<icon-upload></icon-upload>
					</button>
				</div>
        <canvas hidden ref="pdfRender" ></canvas>
				<span id="slipUploadError" class="message error"></span>
				<span id="slipUploadPattern" class="message">{{ $t('slipAdd.upload-pattern') }}</span>
			</div>
		</form>
		<div aria-hidden="true" class="or">{{ $t('misc.or') }}</div>
		<div>
			<h2>{{ $t('slipAdd.title-way-scan') }}</h2>
			<div class="text-center">
				<button type="button" class="btn" @click="startScan" :class="{ 'btn--loading': isLoading }">
					Je prends en photo
					<icon-fullscreen></icon-fullscreen>
				</button>
			</div>
		</div>
		<ConfirmSlipAdd
			:showModal="showModal"
			:statusCode="statusCode"
			@close="showModal = false"
		/>
		<BarcodeScanner
			:showModal="scanStarted"
			@close="scanStarted = false"
			@read="submitScan"
		/>
	</div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';

import { maxLength, minLength, numeric, required } from '@vuelidate/validators';
import { IconFullscreen, IconSave, IconUpload } from '@/components/icons';
import ConfirmSlipAdd from '@/components/modal/ConfirmSlipAdd.vue';
import BarcodeScanner from '@/components/modal/BarcodeScanner.vue';

import ApiError from '../api/error';

const PDFJS = window['pdfjs-dist/build/pdf'];
PDFJS.GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.min.js';

// eslint-disable-next-line no-undef
const codeReader = new ZXingBrowser.BrowserMultiFormatReader();

export default {
	components: {
		BarcodeScanner,
		IconSave,
		IconUpload,
		IconFullscreen,
		ConfirmSlipAdd,
	},
	data: () => ({
		slipNumber: '',
		statusCode: '',
		showModal: false,
		scanStarted: false,
		isLoading: false,
	}),
	setup: () => ({ v$: useVuelidate() }),
	validations: {
		slipNumber: {
			required,
			numeric,
			minLength: minLength(20),
			maxLength: maxLength(20),
		},
	},
	methods: {
		startScan() {
			this.scanStarted = true;
		},
		async decodeImage(dataURL) {
			try {
				const { text } = await codeReader.decodeFromImageUrl(dataURL);
				this.slipNumber = text;
				await this.submitForm();
			} catch (error) {
				console.error(error);
			}
		},
		async submitPDF() {
			const file = this.$refs.pdfInput.files[0];
			this.$refs.pdfInput.value = '';

			if (file.type === 'application/pdf') {
				const uri = URL.createObjectURL(file);
				const pdf = await PDFJS.getDocument({ url: uri }).promise;
				const canvas = this.$refs.pdfRender;
				const canvasContext = canvas.getContext('2d');

				/* eslint-disable no-await-in-loop */
				for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber += 1) {
					const page = await pdf.getPage(pageNumber);
					const viewport = page.getViewport({ scale: 1 });
					canvas.width = viewport.width;
					canvas.height = viewport.height;

					await page.render({ canvasContext, viewport }).promise;
					await this.decodeImage(canvas.toDataURL('image/png'));
				}
				// eslint-enable
			} else if (file && file.type) {
				const fr = new FileReader();
				fr.onload = () => this.decodeImage(fr.result);
				fr.readAsDataURL(file);
			}
		},
		async submitScan(scanned) {
			this.slipNumber = scanned;
			await this.submitForm();
			this.scanStarted = false;
		},
		async submitForm() {
			const isFormCorrect = await this.v$.$validate();
			if (!isFormCorrect) {
				return;
			}

			this.v$.$reset();
			try {
				this.isLoading = true;
				const { statusCode } = await this.$store.dispatch('slip/add', this.slipNumber);
				this.isLoading = false;
				this.statusCode = statusCode;
			} catch (error) {
				this.statusCode = error instanceof ApiError ? error.code : 500;
				this.isLoading = false;
			}

			this.slipNumber = '';
			this.showModal = true;
		},
	},
};
</script>

<style scoped lang="scss">
	@use "./src/assets/scss/abstracts" as *;

	.or {
		display: grid;
		grid-template-columns: minmax(15px, 1fr) auto minmax(15px, 1fr);
		grid-gap: var(--sp);
		align-items: center;
		margin: var(--sp-1\.5) 0;
		font-size: $fs-1;

		&::before,
		&::after {
			content: '';
			display: block;
			height: rem(5px);
			background-color: var(--color-grey-2);
		}
	}
</style>
