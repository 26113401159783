import * as _ from 'lodash';
import axios from 'axios';

const AuthenticationService = {
	async login(email, password) {
		try {
			const { data } = await axios.post('/Login', {
				Email: email,
				Password: password,
			});

			return {
				succeeded: data.IsSuccess,
				messages: data.Messages,
				code: data.Code,
				data: data.Datas,
			};
		} catch (error) {
			return {
				succeeded: false,
				code: 500,
			};
		}
	},
	async register(email, password) {
		try {
			const { data } = await axios.post('/CreateProfile', {
				Email: email,
				Password: password,
			});

			return {
				succeeded: data.IsSuccess,
				messages: data.Messages,
				code: data.Code,
				data: data.Datas,
			};
		} catch (error) {
			return {
				succeeded: false,
				code: 500,
			};
		}
	},
	async fetchUser() {
		try {
			const { data } = await axios.post('/GetProfile');
			return {
				succeeded: data.IsSuccess,
				messages: data.Messages,
				code: data.Code,
				data: _.mapKeys(data.Datas, (v, k) => _.camelCase(k)),
			};
		} catch (error) {
			return {
				succeeded: false,
				code: 500,
			};
		}
	},
	async resetPassword(email) {
		try {
			const { data } = await axios.post('/AskResetPassword', email);

			return {
				succeeded: data.IsSuccess,
				messages: data.Messages,
				code: data.Code,
				data: data.Datas,
			};
		} catch (error) {
			return {
				succeeded: false,
				code: 500,
			};
		}
	},
	async saveNewPassword(email, code, password) {
		try {
			const { data } = await axios.post('/SaveNewPassword', {
				Email: email,
				Password: password,
				Code: code,
			});

			return {
				succeeded: data.IsSuccess,
				messages: data.Messages,
				code: data.Code,
				data: data.Datas,
			};
		} catch (error) {
			return {
				succeeded: false,
				code: 500,
			};
		}
	},
	async changePasssword({ email, password, newPassword }) {
		try {
			const { data } = await axios.post('/ChangePassword', {
				Email: email,
				Password: newPassword,
				OldPassword: password,
			});

			return {
				succeeded: data.IsSuccess,
				messages: data.Messages,
				code: data.Code,
				data: data.Datas,
			};
		} catch (error) {
			return {
				succeeded: false,
				code: 500,
			};
		}
	},
};

export default AuthenticationService;
