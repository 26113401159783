<template>
	<div class="container">
		<div class="row">
			<div class="col-6">
				<h1>Colors</h1>
				<hr>
			</div>
			<div class="col-auto">
				<div class="color text-white" style="--bg: var(--color-primary)">--color-primary</div>
			</div>
			<div class="col-auto">
				<div class="color text-white" style="--bg: var(--color-secondary)">--color-secondary</div>
			</div>
			<div class="col-auto">
				<div class="color text-white" style="--bg: var(--color-passport)">--color-passport</div>
			</div>
			<div class="col-auto">
				<div class="color text-white" style="--bg: var(--color-slip)">--color-slip</div>
			</div>
			<div class="col-auto">
				<div class="color text-white" style="--bg: var(--color-warning)">--color-warning</div>
			</div>
			<div class="col-auto">
				<div class="color text-white" style="--bg: var(--color-success)">--color-success</div>
			</div>
			<div class="col-auto">
				<div class="color text-white" style="--bg: var(--color-black)">--color-black</div>
			</div>
			<div class="col-auto">
				<div class="color" style="--bg: var(--color-white)">--color-white</div>
			</div>
			<div class="col-auto">
				<div class="color" style="--bg: var(--color-grey-1)">--color-grey-1</div>
			</div>
			<div class="col-auto">
				<div class="color" style="--bg: var(--color-grey-2)">--color-grey-2</div>
			</div>
			<div class="col-auto">
				<div class="color" style="--bg: var(--color-grey-3)">--color-grey-3</div>
			</div>
			<div class="col-auto">
				<div class="color" style="--bg: var(--color-grey-4)">--color-grey-4</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
	@use "./src/assets/scss/abstracts" as *;

	.color {
		padding: var(--sp);
		background-color: var(--bg);
	}

	.text-white {
		color: var(--color-white) !important;
	}
</style>
