<template>
	<p class="skip-links" v-if="showNav">
		<a href="#menu" class="light">{{ $t( 'misc.go-to-main-menu' ) }}</a>
	</p>
	<p class="skip-links">
		<a href="#main" class="light">{{ $t( 'misc.go-to-main-content' ) }}</a>
	</p>
</template>

<script>
export default {
	props: [ 'showNav' ],
};
</script>

<style lang="scss" scoped>
	@use "./src/assets/scss/abstracts" as *;

	p {
		--i: 0;
		position: fixed;
		top: 0;
		inset-inline-start: 0;
		z-index: var(--z-max);
		padding: var(--sp) var(--sp-2);
		margin: 0;
		color: var(--color-white);
		background-color: var(--color-primary);
		transform: translateY(calc(100% * (var(--i) - 1)));
		transition: transform 0.2s;

		&:focus-within {
			--i: 1;
		}
	}
</style>
