<template>
	<svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.5 23.5">
		<path fill="currentColor" d="M16.75 23.5h-16a.7502.7502 0 0 1-.75-.75v-22A.7502.7502 0 0 1 .75 0h10a.74942.74942 0 0 1 .53027.21973l6 6A.75027.75027 0 0 1 17.5 6.75v16a.7502.7502 0 0 1-.75.75ZM1.5 22H16V7.06055L10.43945 1.5H1.5Z"/>
		<g fill="currentColor">
			<path d="m10.75.75 3 3 3 3h-6Z"/>
			<path d="M16.75 7.25h-6a.49971.49971 0 0 1-.5-.5v-6a.5.5 0 0 1 .85352-.35352l6 6A.5.5 0 0 1 16.75 7.25Zm-5.5-1h4.293L11.25 1.957Z"/>
		</g>
		<g>
			<path d="M12 12.75H5.5a.75.75 0 0 1 0-1.5H12a.75.75 0 0 1 0 1.5Z"/>
			<path d="M12 16.75H5.5a.75.75 0 0 1 0-1.5H12a.75.75 0 0 1 0 1.5Z"/>
		</g>
	</svg>
</template>
