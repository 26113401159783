<template>
	<div class="content bg-grey-1">
		<div class="px py-1.5">
			<FormMessage v-if="success" :message="$t('account.passport-success')" type="success" />
			<FormMessage v-if="errorCode" :message="$t('error.' + errorCode)" type="error" />
			<FormMessage v-if="scanError" :message="$t('passport.scan.error')" type="error" />
			<form @submit.prevent="submitForm">
				<div class="row align-center">
					<div class="col-6">
						<div class="form-group" :class="{ 'error': v$.form.passportNumber.$error }">
							<label for="userPassportNumber">{{ $t('account.passport') }}</label>
							<InputPassportScanner
								id="userPassportNumber"
								:ariaDescribedby="v$.form.passportNumber.$error ? 'userPassportNumberError' : null"
								v-model="form.passportNumber"
								@read="read"
								@scanError="scanError = true"
							/>
							<span id="userPassportNumberError" class="message error" v-if="v$.form.passportNumber.$error">
								<template v-if="v$.form.passportNumber.required.$invalid">{{ $t('misc.is-required-field') }}</template>
							</span>
						</div>
					</div>
					<div class="col-6">
						<div class="form-group" :class="{ 'error': v$.form.countryIso.$error }">
							<label for="userCountryIso">{{ $t('misc.country-of-residence') }}</label>
							<select
								v-model="form.countryIso"
								id="userCountryIso"
								:aria-describedby="v$.form.countryIso.$error ? 'userCountryIsoError' : null"
								required
							>
								<option
									v-for="nationality in nationalities"
									:key="nationality.numeric"
									:value="parseInt(nationality.numeric)"
								>
									{{ nationality.name }}
								</option>
							</select>
							<span id="userCountryIsoError" class="message error" v-if="v$.form.countryIso.$error">
								<template v-if="v$.form.countryIso.required.$invalid">{{ $t('misc.is-required-field') }}</template>
							</span>
						</div>
					</div>
					<div class="col-6 text-right">
						<button type="submit" class="btn" :class="{ 'btn--loading': isLoading }">
							{{ $t('account.update-passport') }}
							<icon-check></icon-check>
						</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import _ from 'lodash';
import IconCheck from '../../components/icons/IconCheck.vue';
import PassportService from '../../api/passport';
import FormMessage from '../../components/FormMessage.vue';
import InputPassportScanner from '../../components/form/InputPassportScanner.vue';
import nationalities from '../../lang/nationalities.json';

export default {
	components: {
		InputPassportScanner,
		FormMessage,
		IconCheck,
	},
	data: () => ({
		form: {
			passportNumber: '',
			countryIso: '',
		},
		success: false,
		errorCode: '',
		isLoading: false,
		scanError: false,
		nationalities,
	}),
	setup: () => ({ v$: useVuelidate() }),
	validations: {
		form: {
			passportNumber: {	required },
			countryIso: { required },
		},
	},
	computed: {
		...mapState('authentication', [ 'user' ]),
	},
	methods: {
		read(passport) {
			this.form.passportNumber = passport.documentNumber;
			this.form.countryIso = this.convertNationalityToNumericCode(passport.nationality);
			this.scanError = false;
		},
		convertNationalityToNumericCode(alpha3Code) {
			const nationality = _.find(this.nationalities, { alpha3Code });
			return (nationality) ? nationality.numeric : 0;
		},
		async submitForm() {
			const isFormCorrect = await this.v$.$validate();
			if (!isFormCorrect) {
				return;
			}

			this.errorCode = '';
			this.success = false;
			this.isLoading = true;
			const response = await PassportService.add(this.form);
			if (response.succeeded) {
				this.$store.commit('authentication/setPassport', {
					number: this.form.passportNumber,
					iso: this.form.countryIso,
				});

				this.success = true;
				this.isLoading = false;
			} else {
				this.success = false;
				this.errorCode = response.code;
				this.isLoading = false;
			}
		},
	},
	mounted() {
		if (this.user) {
			this.form.passportNumber = this.user.passport;
			this.form.countryIso = this.user.countryIso;
		}
	},
};
</script>

<style lang="scss" scoped>
.content {
	min-height: var(--full-height);
}
</style>
