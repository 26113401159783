<template>
	<modal :showModal="showModal">
		<template v-slot:header>
			{{ $t('forget-password.title') }}
		</template>
		<template v-slot:body>
			<form @submit.prevent="resetPassword" v-show="showFormEmail">
				<p>{{ $t('forget-password.explanations-1') }}</p>
				<div class="row align-center">
					<div class="col-6">
						<div class="form-group">
							<label for="userEmailReset">{{ $t( 'misc.your-email' ) }}</label>
							<input type="email" id="userEmailReset" v-model="email" autocomplete="username" required>
						</div>
					</div>
					<div class="col-auto ml-a">
						<button type="submit" class="btn" :class="{ 'btn--loading': isLoading }">
							{{ $t('signin.validate') }}
							<icon-check></icon-check>
						</button>
					</div>
				</div>
			</form>
			<form @submit.prevent="saveNewPassword" v-show="showFormCode">
				<FormMessage v-if="errorCode" :message="$t('error.' + errorCode)" type="error" />
				<p>{{ $t('forget-password.explanations-2') }}</p>
				<div class="row align-center">
					<div class="col-6">
						<div class="form-group">
							<label for="code">{{ $t('forget-password.code') }}</label>
							<input type="text" id="code" v-model="code" autocomplete="code" required>
						</div>
					</div>
					<div class="col-6">
						<div class="form-group">
							<InputPassword v-model="password" showPattern="true"></InputPassword>
						</div>
					</div>
					<div class="col-auto ml-a">
						<button type="submit" class="btn" :class="{ 'btn--loading': isLoading }">
							{{ $t('signin.validate') }}
							<icon-check></icon-check>
						</button>
					</div>
				</div>
			</form>
		</template>
	</modal>
</template>

<script>
import Modal from './Modal.vue';
import AuthenticationService from '@/api/authentication';
import InputPassword from '@/components/form/InputPassword.vue';
import { IconCheck } from '@/components/icons';
import FormMessage from '../FormMessage.vue';

export default {
	components: {
		FormMessage,
		Modal,
		InputPassword,
		IconCheck,
	},
	name: 'ForgetPassword',
	props: [ 'showModal' ],
	data: () => ({
		email: '',
		password: '',
		code: '',
		showFormCode: false,
		showFormEmail: true,
		errorCode: '',
		isLoading: false,
	}),
	methods: {
		init() {
			this.email = '';
			this.password = '';
			this.code = '';
			this.showFormCode = false;
			this.showFormEmail = true;
		},
		async resetPassword() {
			this.errorCode = '';
			this.isLoading = true;
			const response = await AuthenticationService.resetPassword(this.email);
			if (response.succeeded) {
				this.showFormCode = true;
				this.showFormEmail = false;
			}
			this.isLoading = false;
		},
		async saveNewPassword() {
			this.errorCode = '';
			this.isLoading = true;
			const response = await AuthenticationService.saveNewPassword(this.email, this.code, this.password);
			if (response.succeeded) {
				this.isLoading = false;
				this.$emit('close');
				this.$emit('success');
				this.init();
			} else {
				this.errorCode = response.code;
				this.isLoading = false;
			}
		},
	},
};
</script>
