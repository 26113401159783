import { createI18n } from 'vue-i18n';
import en from '@/lang/en.json';
import fr from '@/lang/fr.json';

export default function initI18n() {
	return createI18n({
		locale: 'fr',
		messages: { en, fr },
		fallbackLocale: 'en',
	});
}
