<template>
	<div class="container">
		<div class="row">
			<div class="col-6">
				<h1>Buttons</h1>
				<hr>
			</div>
			<div class="col-6">
				<h2>Base</h2>
			</div>
			<div class="col-auto">
				<a href="#" class="btn">Link</a>
			</div>
			<div class="col-auto">
				<button type="button" class="btn">Button</button>
			</div>
			<div class="col-auto">
				<input type="submit" class="btn" value="Input">
			</div>
			<div class="col-auto">
				<button type="button" class="btn" aria-label="button label">
					<icon-check></icon-check>
				</button>
			</div>
			<div class="col-auto">
				<button type="button" class="btn">
					<icon-arrow-left></icon-arrow-left>
					Button
				</button>
			</div>
			<div class="col-auto">
				<button type="button" class="btn">
					Button
					<icon-chevron-right></icon-chevron-right>
				</button>
			</div>
			<div class="col-auto">
				<button type="button" class="btn" aria-label="button label">
					<icon-folder></icon-folder>
				</button>
			</div>
			<div class="col-6">
				<h2>Base - Disabled state</h2>
			</div>
			<div class="col-auto">
				<button type="button" class="btn" disabled>Button</button>
			</div>
			<div class="col-auto">
				<input type="submit" class="btn" value="Input" disabled>
			</div>
			<div class="col-auto">
				<button type="button" class="btn" aria-label="button label" disabled>
					<icon-check></icon-check>
				</button>
			</div>
			<div class="col-auto">
				<button type="button" class="btn" disabled>
					<icon-arrow-left></icon-arrow-left>
					Button
				</button>
			</div>
			<div class="col-auto">
				<button type="button" class="btn" disabled>
					Button
					<icon-chevron-right></icon-chevron-right>
				</button>
			</div>
			<div class="col-auto">
				<button type="button" class="btn" aria-label="button label" disabled>
					<icon-folder></icon-folder>
				</button>
			</div>
			<div class="col-6">
				<h2>White</h2>
			</div>
			<div class="col-auto">
				<button type="button" class="btn btn--white">Button</button>
			</div>
			<div class="col-auto">
				<button type="button" class="btn btn--white" aria-label="button label">
					<icon-check></icon-check>
				</button>
			</div>
			<div class="col-auto">
				<button type="button" class="btn btn--white">
					Button
					<icon-chevron-right></icon-chevron-right>
				</button>
			</div>
			<div class="col-6">
				<h2>Loading</h2>
			</div>
			<div class="col-auto">
				<button type="button" class="btn btn--loading">Button</button>
			</div>
			<div class="col-auto">
				<button type="button" class="btn btn--loading" aria-label="button label">
					<icon-check></icon-check>
				</button>
			</div>
			<div class="col-auto">
				<button type="button" class="btn btn--loading">
					Button
					<icon-chevron-right></icon-chevron-right>
				</button>
			</div>
			<div class="col-6">
				<h2>Ghost</h2>
			</div>
			<div class="col-auto">
				<a href="#" class="btn btn--ghost">Link</a>
			</div>
			<div class="col-auto">
				<button type="button" class="btn btn--ghost">Button</button>
			</div>
			<div class="col-auto">
				<input type="submit" class="btn btn--ghost" value="Input">
			</div>
			<div class="col-auto">
				<button type="button" class="btn btn--ghost" aria-label="button label">
					<icon-check></icon-check>
				</button>
			</div>
			<div class="col-auto">
				<button type="button" class="btn btn--ghost">
					<icon-arrow-left></icon-arrow-left>
					Button
				</button>
			</div>
			<div class="col-auto">
				<button type="button" class="btn btn--ghost">
					Button
					<icon-chevron-right></icon-chevron-right>
				</button>
			</div>
			<div class="col-auto">
				<button type="button" class="btn btn--ghost" aria-label="button label">
					<icon-folder class="dual"></icon-folder>
				</button>
			</div>
			<div class="col-6">
				<h2>Like a link</h2>
			</div>
			<div class="col-auto">
				<button type="button" class="btn btn--link">
					Button
				</button>
			</div>
			<div class="col-auto">
				<button type="button" class="btn btn--link">
					<icon-arrow-left></icon-arrow-left>
					Button
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { IconArrowLeft, IconChevronRight, IconCheck, IconFolder } from '@/components/icons';

export default {
	components: {
		IconArrowLeft,
		IconChevronRight,
		IconCheck,
		IconFolder,
	},
};
</script>

<style scoped>
	h2 {
		margin-top: var(--sp);
	}
</style>
