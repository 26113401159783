<template>
	<p id="title-page" tabindex="-1" class="sr-only"></p>
	<SkipLinks :showNav=showNav></SkipLinks>
	<Header></Header>
	<Nav v-if="showNav"></Nav>
	<main id="main" role="main">
		<router-view/>
  </main>
</template>

<script>
import _ from 'lodash';
import SkipLinks from '@/components/nav/SkipLinks.vue';
import Header from '@/components/nav/Header.vue';
import Nav from '@/components/nav/Nav.vue';

export default {
	components: {
		SkipLinks,
		Header,
		Nav,
	},
	computed: {
		showNav() {
			return _.get(this.$route, 'meta.authentication.required', false)
				&& !this.$route.name.includes('Register Step 2');
		},
	},
	created() {
		document.documentElement.setAttribute('lang', this.$i18n.locale);
		document.documentElement.setAttribute('dir', this.$i18n.locale === 'ar' ? 'rtl' : 'ltr');
		const appHeight = () => {
			const vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		};
		window.addEventListener('resize', appHeight);
		appHeight();
	},
	mounted() {
		const locale = localStorage.getItem('language');
		if (locale) {
			this.$i18n.locale = locale;
		}
	},
	watch: {
		$route(to) {
			document.title = `${this.$i18n.t(to.meta.title || 'misc.default-title-page')} - Cash Paris`;
			document.querySelector('#title-page').textContent = document.title;
			document.querySelector('#title-page').focus();
		},
	},
};
</script>

<style lang="scss">
  @use "./assets/scss/styles";

	#app {
		display: flex;
		flex-direction: column;
		min-height: inherit;
	}

	[role="navigation"] {
		order: 10;
	}

	main {
		flex: 1 0 auto;
		scroll-margin-top: calc(var(--header-height) + var(--sp));
	}
</style>
