<template>
		<router-link :to="link" :title="title">
			<div class="icon" :style="bgIcon ? '--bg: var(--color-'+bgIcon+')' : null">
				<slot/>
			</div>
			{{ text }}
			<icon-chevron-right class="chevron" />
		</router-link>
</template>

<script>
import { IconChevronRight } from '@/components/icons';

export default {
	name: 'LinkAccount',
	props: [ 'link', 'text', 'title', 'bgIcon' ],
	components: {
		IconChevronRight,
	},
};
</script>

<style scoped lang="scss">
	@use "./src/assets/scss/abstracts" as *;

	a {
		--p: var(--sp);
		display: flex;
		align-items: center;
		column-gap: var(--p);
		padding-top: var(--p);
		padding-bottom: var(--p);
		padding-inline-start: var(--p);
		padding-inline-end: calc(var(--p) * 2);
		margin-bottom: var(--sp);
		border-radius: rem(8px);
		word-break: break-word;
		text-decoration: none;
		background-color: var(--color-grey-1);
	}

	.icon {
		display: flex;
		align-items: center;
		justify-content: center;
		height: var(--icon-size);
		padding: var(--sp-0\.75);
		border-radius: rem(8px);
		font-size: rem(22px);
		background-color: var(--bg, var(--color-slip));
		color: var(--color-white);

		:slotted(svg) {
			margin: 0;
		}
	}

	.chevron {
		flex: 0 0 auto;
		margin-inline-end: 0;
		margin-inline-start: auto;
		font-size: rem(12px);
	}
</style>
