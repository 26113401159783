<template>
	<div class="add">
		<input
			type="text"
			:id="id"
			:value="modelValue"
			@input="$emit('update:modelValue', $event.target.value)"
			:aria-describedby="ariaDescribedby"
			required
		>
		<button
			type="button"
			class="btn btn--ghost"
			:aria-controls="id"
			:aria-label="$t( 'password.scan' )"
			@click="showModal = true"
			style="--px: 0"
		>
			<icon-fullscreen></icon-fullscreen>
		</button>
	</div>
	<PassportScanner
		:showModal="showModal"
		@close="showModal = false"
		@read="$emit('read', $event)"
		@scanError="$emit('scanError')"
	/>
</template>

<script>
import { IconFullscreen } from '@/components/icons';
import PassportScanner from '../modal/PassportScanner.vue';

export default {
	components: {
		PassportScanner,
		IconFullscreen,
	},
	props: [ 'id', 'ariaDescribedby', 'modelValue' ],
	emits: [ 'read', 'scanError' ],
	data: () => ({
		showModal: false,
	}),
};
</script>
