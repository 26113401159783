import * as _ from 'lodash';
import axios from 'axios';

function convertCentToEuro(cent) {
	return _.round(cent / 100, 2);
}

function formatDataRow(item) {
	return {
		id: item.Id,
		number: item.VoucherNumber,
		vroId: item.VroId,
		invoiceCount: item.InvoiceCount,
		statusCode: item.StatusCode,
		storeCode: item.StoreCode,
		storeName: item.StoreName,
		totalHT: convertCentToEuro(item.TotalHT),
		totalTTC: convertCentToEuro(item.TotalTTC),
		totalInvoice: convertCentToEuro(item.TotalInvoice),
		totalRefund: convertCentToEuro(item.TotalRefund),
		totalTVA: convertCentToEuro(item.TotalTVA),
		createdAt: new Date(item.DateCre),
		refundAt: item.DateRefund ? new Date(item.DateRefund) : null,
	};
}

const SlipService = {
	async fetchAll() {
		try {
			const { data } = await axios.get('/GetBDV');

			return {
				succeeded: data.IsSuccess,
				messages: data.Messages,
				code: data.Code,
				data: _.map(data.Datas, formatDataRow),
			};
		} catch (error) {
			return {
				succeeded: false,
				code: 500,
			};
		}
	},
	async fetch(slipNumber) {
		try {
			const { data } = await axios.post('/GetDetailsBDV', JSON.stringify(slipNumber));

			return {
				succeeded: data.IsSuccess,
				messages: data.Messages,
				code: data.Code,
				data: formatDataRow(data.Datas),
			};
		} catch (error) {
			return {
				succeeded: false,
				code: 500,
			};
		}
	},
	async add(slipNumber) {
		try {
			const { data } = await axios.post('/AddBdvtoProfile', JSON.stringify(slipNumber));

			return {
				succeeded: data.IsSuccess,
				messages: data.Messages,
				code: data.Code,
				data: data.Datas,
			};
		} catch (error) {
			return {
				succeeded: false,
				code: 500,
			};
		}
	},
};

export default SlipService;
