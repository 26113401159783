import authService from '../services/authService';
import ApiError from '../api/error';

export default {
	namespaced: true,
	state: () => ({
		accessToken: '',
		user: null,
	}),
	getters: {
	},
	mutations: {
		setAccessToken(state, accessToken) {
			state.accessToken = accessToken;
			localStorage.setItem('accessToken', accessToken);
		},
		setUser(state, user) {
			state.user = user;
		},
		setPassport(state, { number, iso }) {
			state.user.passport = number;
			state.user.countryIso = iso;
		},
		clearLoginContext(state) {
			state.accessToken = '';
			state.user = null;
			localStorage.removeItem('accessToken');
		},
	},
	actions: {
		async login({ commit, dispatch }, { email, password }) {
			const response = await authService.signin(email, password);
			if (!response.succeeded) {
				commit('clearLoginContext');
				throw new ApiError(response.code);
			}

			commit('setAccessToken', response.data);
			await dispatch('fetchUser');
		},
		async register({ commit, dispatch }, { email, password }) {
			const response = await authService.signup(email, password);
			if (!response.succeeded) {
				commit('clearLoginContext');
				throw new ApiError(response.code);
			}

			commit('setAccessToken', response.data);
			await dispatch('fetchUser');
		},
		async fetchUser({ commit }) {
			const response = await authService.me();
			if (!response.succeeded) {
				commit('clearLoginContext');
				throw new ApiError(response.code);
			}

			commit('setUser', response.data);
			return response.data;
		},
		async restoreLogin({ commit, dispatch }) {
			const accessToken = localStorage.getItem('accessToken');
			if (accessToken) {
				commit('setAccessToken', accessToken);
				await dispatch('fetchUser');
			}
		},
	},
};
