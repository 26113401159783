<template>
	<svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 21">
		<path fill="currentColor" fill-rule="evenodd" d="M2.25 5.25A2.75 2.75 0 0 1 5 2.5h10a2.75 2.75 0 0 1 2.75 2.75v13A2.75 2.75 0 0 1 15 21H5a2.75 2.75 0 0 1-2.75-2.75ZM5 4a1.25 1.25 0 0 0-1.25 1.25v13A1.25 1.25 0 0 0 5 19.5h10a1.24994 1.24994 0 0 0 1.25-1.25v-13A1.25 1.25 0 0 0 15 4Z"/>
		<path fill="currentColor" fill-rule="evenodd" d="M0 6.5a.75.75 0 0 1 .75-.75h18.5a.75.75 0 0 1 0 1.5H.75A.75.75 0 0 1 0 6.5Z"/>
		<g>
		<path fill-rule="evenodd" d="M10 9a.75.75 0 0 1 .75.75v6.5a.75.75 0 0 1-1.5 0v-6.5A.75.75 0 0 1 10 9Z"/>
			<path fill-rule="evenodd" d="M6.25 9a.75.75 0 0 1 .75.75v6.5a.75.75 0 0 1-1.5 0v-6.5A.75.75 0 0 1 6.25 9Z"/>
			<path fill-rule="evenodd" d="M13.75 9a.75.75 0 0 1 .75.75v6.5a.75.75 0 0 1-1.5 0v-6.5a.75.75 0 0 1 .75-.75Z"/>
		</g>
		<path fill="currentColor" fill-rule="evenodd" d="M8.5 1.5a.25.25 0 0 0-.25.25v1.5h-1.5v-1.5A1.75 1.75 0 0 1 8.5 0h3a1.75 1.75 0 0 1 1.75 1.75v1.5h-1.5v-1.5a.25.25 0 0 0-.25-.25Z"/>
	</svg>
</template>
