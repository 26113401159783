<template>
	<div class="container">
		<div class="row">
			<div class="col-6">
				<h1>Cards</h1>
				<hr>
			</div>
			<div class="col-6">
				<h2>Text only</h2>
				<Card text="Cash Paris est opérateur officiel de l'Aéroport de Paris." img="//picsum.photos/200"></Card>
			</div>
			<div class="col-6">
				<h2 class="mt">Text & link</h2>
				<Card link="#" text="Où trouver un bureau de détaxe Cash Paris ?" img="//picsum.photos/300"></Card>
			</div>
			<div class="col-6">
				<h2 class="mt">Text & number</h2>
				<Card number="1" text="Scannez votre passeport depuis l'application" img="//picsum.photos/350"></Card>
			</div>
			<div class="col-6">
				<h2 class="mt">Text, number & link</h2>
				<Card link="#" number="1" text="Scannez votre passeport depuis l'application" img="//picsum.photos/375"></Card>
			</div>
		</div>
	</div>
</template>

<script>
import Card from '@/components/content/Card.vue';

export default {
	components: {
		Card,
	},
};
</script>
