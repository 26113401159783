<template>
	<div class="container">
		<div class="row">
			<div class="col-6">
				<h1>Slip cards</h1>
				<hr>
			</div>
			<div class="col-6">
				<h2>Abstract</h2>
			</div>
			<div class="col-6" v-for="slip in slips" :key="slip.BdvNumber">
				<SlipCard abstract="true" :slip="slip"/>
			</div>
			<div class="col-6">
				<h2>Full</h2>
			</div>
			<div class="col-6" v-for="slip in slips" :key="slip.BdvNumber">
				<SlipCard :slip="slip"/>
			</div>
		</div>
	</div>
</template>

<script>
import SlipCard from '@/components/SlipCard.vue';
import SlipService from '@/api/slip';

export default {
	data: () => ({
		slips: [],
		loading: false,
	}),
	components: {
		SlipCard,
	},
	methods: {
		async fetchSlip() {
			this.loading = true;
			try {
				const response = await SlipService.fetchAll();
				this.slips = response.data;
			} finally {
				this.loading = false;
			}
		},
	},
	mounted() {
		this.fetchSlip();
	},
};
</script>
