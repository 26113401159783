<template>
	<modal :showModal="showModal" @close="$emit('close')">
		<template v-slot:header>
			{{ $t('passport.scan.title') }}
		</template>
		<template v-slot:body>
			<p v-if="!videoMedias.length" class="p text-center bg-grey-1">Pas de media</p>
			<div v-else class="wrap">
				<video ref="camera" autoplay playsinline muted></video>
				<button type="button" class="btn btn--white scan" :aria-label="$t('passport.scan.action')" @click="capture">
					<icon-fullscreen></icon-fullscreen>
				</button>
				<canvas v-show="captured" ref="picture"></canvas>
			</div>
		</template>
	</modal>
</template>

<script>
import _ from 'lodash';
import Modal from './Modal.vue';
import MRZService from '@/api/mrz';
import { IconFullscreen } from '../icons';

export default {
	components: {
		IconFullscreen,
		Modal,
	},
	props: [ 'showModal' ],
	emits: [ 'read', 'scanError', 'close' ],
	data: () => ({
		medias: [],
		stream: null,
		captured: false,
	}),
	computed: {
		videoMedias() {
			return _.filter(this.medias, { kind: 'videoinput' });
		},
	},
	methods: {
		stop() {
			if (this.stream) {
				this.stream.getTracks().forEach((track) => track.stop());
				this.stream = null;
			}
		},
		async start() {
			this.stop();
			const constraints = {
				video: {
					facingMode: 'environment',
					width: { max: window.innerWidth },
					height: { max: window.innerHeight },
					// eslint-disable-next-line no-restricted-globals
					aspectRatio: { exact: 1.777 },
				},
			};
			this.stream = await navigator.mediaDevices.getUserMedia(constraints);
			this.$refs.camera.srcObject = this.stream;
		},
		onResize() {
			if (this.stream) {
				this.start();
			}
		},
		async capture() {
			this.$refs.picture.width = this.$refs.camera.videoWidth;
			this.$refs.picture.height = this.$refs.camera.videoHeight;
			this.$refs.picture.getContext('2d').drawImage(this.$refs.camera, 0, 0);
			this.stop();
			this.captured = true;
			try {
				const infos = await MRZService.extractInfos(this.$refs.picture.toDataURL('image/png'));
				this.$emit('read', infos);
			} catch (error) {
				this.captured = false;
				this.$emit('scanError');
			}
			this.$emit('close');
		},
	},
	async mounted() {
		window.addEventListener('resize', this.onResize);
		if (navigator.mediaDevices) {
			this.medias = await navigator.mediaDevices.enumerateDevices();
		}
	},
	unmounted() {
		window.removeEventListener('resize', this.onResize);
		this.stop();
	},
	watch: {
		showModal() {
			if (this.showModal) {
				this.start();
			} else {
				this.stop();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	.wrap {
		position: relative;
		display: flex;

		video {
			width: 100%;
		}

		canvas {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	.scan {
		position: absolute;
		bottom: var(--sp);
		right: var(--sp);
	}
</style>
