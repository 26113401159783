import { createRouter, createWebHistory } from 'vue-router';

import store from '../store/index';

import Home from '../views/Home.vue';
import Welcome from '../views/Welcome.vue';
import Login from '../views/Login.vue';
import IdentityRegistration from '../views/IdentityRegistration.vue';
import PassportRegistration from '../views/PassportRegistration.vue';
import Slip from '../views/Slip.vue';
import Stylesguide from '../views/Stylesguide.vue';
import Steps from '../views/onboarding/Steps.vue';
import Reassurances from '../views/onboarding/Reassurances.vue';
import HowItWorks from '../views/onboarding/HowItWorks.vue';
import Account from '../views/account/Account.vue';
import AccountPassport from '../views/account/AccountPassport.vue';
import AccountConnectionInformation from '../views/account/AccountConnectionInformation.vue';
import AccountUpdateLanguage from '../views/account/AccountUpdateLanguage.vue';
import SlipAdd from '../views/SlipAdd.vue';
import SelectLanguage from '@/views/SelectLanguage.vue';
import History from '@/views/History.vue';

const routes = [ {
	path: '/login',
	name: 'Login',
	component: Login,
	meta: {
		title: 'login.title',
		header: {
			back: {
				title: 'back.home',
				routeName: 'Home',
			},
		},
	},
}, {
	path: '/register-1',
	name: 'Register Step 1',
	component: IdentityRegistration,
	meta: {
		title: 'register.title-step-1',
		header: {
			back: {
				title: 'back.login',
				routeName: 'Login',
			},
		},
	},
}, {
	path: '/register-2',
	name: 'Register Step 2',
	component: PassportRegistration,
	meta: {
		title: 'register.title-step-2',
		header: {
			logo: false,
			secondaryLink: {
				title: 'register.skip',
				routeName: 'Welcome',
			},
		},
		authentication: {
			required: true,
		},
	},
}, {
	path: '/home',
	name: 'Home',
	component: Home,
	meta: {
		title: 'home.title',
		header: {
			showLanguageSelect: true,
		},
	},
}, {
	path: '/select-language',
	name: 'SelectLanguage',
	component: SelectLanguage,
	meta: {
		title: 'account.change-language',
		header: {
			back: {
				title: 'back.home',
				routeName: 'Home',
			},
		},
	},
}, {
	path: '/slip',
	name: 'Slip',
	component: Slip,
	meta: {
		title: 'slip.title',
		authentication: {
			required: true,
		},
		header: {
			cash: true,
		},
	},
}, {
	path: '/slip-add',
	name: 'Slip Add',
	component: SlipAdd,
	meta: {
		title: 'nav.add-slip',
		authentication: {
			required: true,
		},
		header: {
			back: {
				title: 'back.slip',
				routeName: 'Slip',
			},
		},
	},
}, {
	path: '/history',
	name: 'History',
	component: History,
	meta: {
		authentication: {
			required: true,
		},
		header: {
			cash: true,
		},
	},
}, {
	path: '/stylesguide',
	name: 'Styles Guide',
	component: Stylesguide,
}, {
	path: '/steps',
	name: 'Steps',
	component: Steps,
	meta: {
		title: 'steps.title',
		header: {
			secondaryLink: {
				title: 'howItWorks.go-to',
				routeName: 'How it works ?',
			},
			back: {
				history: true,
			},
		},
	},
}, {
	path: '/reassurances',
	name: 'Reassurances',
	component: Reassurances,
	meta: {
		title: 'reassurance.title',
		header: {
			secondaryLink: {
				title: 'howItWorks.go-to',
				routeName: 'How it works ?',
			},
			back: {
				history: true,
			},
		},
	},
}, {
	path: '/how-it-works',
	name: 'How it works ?',
	component: HowItWorks,
	meta: {
		title: 'howItWorks.title',
		header: {
			back: {
				history: true,
			},
		},
	},
}, {
	path: '/welcome',
	name: 'Welcome',
	component: Welcome,
	meta: {
		title: 'welcome.title',
		authentication: {
			required: true,
		},
		header: {
			cash: true,
		},
	},
}, {
	path: '/account',
	name: 'Account',
	component: Account,
	meta: {
		title: 'account.title',
		authentication: {
			required: true,
		},
		header: {
			cash: true,
		},
	},
}, {
	path: '/account-passport',
	name: 'AccountPassport',
	component: AccountPassport,
	meta: {
		title: 'account.passport',
		authentication: {
			required: true,
		},
		header: {
			back: {
				title: 'back.account',
				routeName: 'Account',
			},
		},
	},
}, {
	path: '/account-connection-information',
	name: 'AccountConnectionInformation',
	component: AccountConnectionInformation,
	meta: {
		title: 'account.connection-title-page',
		authentication: {
			required: true,
		},
		header: {
			back: {
				title: 'back.account',
				routeName: 'Account',
			},
		},
	},
}, {
	path: '/account-update-language',
	name: 'AccountUpdateLanguage',
	component: AccountUpdateLanguage,
	meta: {
		title: 'account.change-language',
		authentication: {
			required: true,
		},
		header: {
			back: {
				title: 'back.account',
				routeName: 'Account',
			},
		},
	},
}, {
	path: '/:pathMatch(.*)*',
	redirect: '/home',
} ];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

router.beforeEach(async (to, from, next) => {
	if (!store.state.authentication.accessToken) {
		await store.dispatch('authentication/restoreLogin').catch(() => null);
	} else if (!store.state.authentication.user) {
		await store.dispatch('authentication/fetchUser').catch(() => null);
	}

	if (to.meta.authentication && to.meta.authentication.required) {
		if (!store.state.authentication.user) {
			return next('/login');
		}
	}
	return next();
});

export default router;
