<template>
	<div class="container pt-1.5">
		<div v-if="sortedSlipsByStatusPaid.length">
			<h2 class="fs-1">{{ $t('slip.history-operations')}}</h2>
			<section v-for="(slips, date) in groupedByMonthSlipsPaid" :key="date">
				<h3 class="fs-3 text-slip font-sans-serif fw-bold mt-2 mb-1">{{ date }}</h3>
				<Operation v-for="slip in slips" :key="slip.number" :slip="slip" class="mb" />
			</section>
		</div>
		<div v-else>
			<p>
				<span >{{ $t('slip.no-operation') }}</span>
			</p>
			<h2 class="my-2 text-secondary">{{ $t('slip.start-operation') }} :</h2>
			<div class="row">
				<div class="col-auto fw-bold" aria-hidden="true">·</div>
				<div class="col">
					<p v-html="$t('slip.touch-button-add')"></p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import Operation from '@/components/Operation.vue';

export default {
	components: {
		Operation,
	},
	computed: {
		...mapGetters('slip', [ 'totalRefund', 'sortedSlipsByStatusPaid' ]),
		groupedByMonthSlipsPaid() {
			return _.groupBy(this.sortedSlipsByStatusPaid, (slip) => {
				return `${slip.createdAt.toLocaleDateString(this.$i18n.locale, { year: 'numeric', month: 'long' })}`;
			});
		},
	},
	mounted() {
		this.$store.dispatch('slip/fetchAll');
	},
};
</script>
