<template>
	<div class="container">
		<div class="row">
			<div class="col-6">
				<h1>Pastil</h1>
				<hr>
			</div>
			<div class="col-6">
				<div class="row">
					<div class="col-auto">
						<div class="pastil" aria-hidden="true">
							0
						</div>
					</div>
					<div class="col-auto">
						<div class="pastil" aria-hidden="true">
							1
						</div>
					</div>
					<div class="col-auto">
						<div class="pastil" aria-hidden="true">
							9
						</div>
					</div>
					<div class="col-auto">
						<div class="pastil" aria-hidden="true">
							10
						</div>
					</div>
					<div class="col-auto">
						<div class="pastil" aria-hidden="true">
							99
						</div>
					</div>
					<div class="col-auto">
						<div class="pastil" aria-hidden="true" style="--bg: var(--color-secondary)">
							<icon-bell></icon-bell>
						</div>
					</div>
					<div class="col-auto">
						<div class="pastil" aria-hidden="true" style="--bg: var(--color-warning)">
							<icon-info></icon-info>
						</div>
					</div>
					<div class="col-auto">
						<div class="pastil" aria-hidden="true" style="--bg: var(--color-success)">
							<icon-check></icon-check>
						</div>
					</div>
					<div class="col-6"></div>
					<div class="col-auto">
						<div class="pastil" aria-hidden="true" style="--fs: 2rem; --bg: var(--color-secondary)">
							<icon-bell></icon-bell>
						</div>
					</div>
					<div class="col-auto">
						<div class="pastil" aria-hidden="true" style="--fs: 2rem; --bg: var(--color-warning)">
							<icon-info></icon-info>
						</div>
					</div>
					<div class="col-auto">
						<div class="pastil" aria-hidden="true" style="--fs: 2rem; --bg: var(--color-success)">
							<icon-check></icon-check>
						</div>
					</div>
					<div class="col-6"></div>
					<div class="col-auto">
						<button type="button" class="btn">
							Button
							<div class="pastil" aria-hidden="true">5</div>
						</button>
					</div>
					<div class="col-auto">
						<button type="button" class="btn">
							Button
							<icon-check></icon-check>
							<div class="pastil" aria-hidden="true">5</div>
						</button>
					</div>
					<div class="col-auto">
						<button type="button" class="btn">
							<icon-check></icon-check>
							<div class="pastil" aria-hidden="true">5</div>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { IconBell, IconCheck, IconInfo } from '@/components/icons';

export default {
	components: {
		IconBell,
		IconCheck,
		IconInfo,
	},
};
</script>

<style lang="scss" scoped>
	@use "./src/assets/scss/abstracts" as *;
</style>
