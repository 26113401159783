<template>
	<div class="intro">
		<h2>
			<span>{{ $t('register.please') }}</span>
			<span class="h1">{{ $t('register.infos') }}</span>
		</h2>
	</div>
	<div class="container mt-1.5">
		<PassportRegisterForm />
	</div>
</template>

<script>
import PassportRegisterForm from '@/components/PassportRegisterForm.vue';

export default {
	components: {
		PassportRegisterForm,
	},
};
</script>

<style lang="scss" scoped>
@use "./src/assets/scss/abstracts" as *;

.intro {
	padding: var(--sp-1\.5) var(--sp);
	background-color: var(--color-grey-2);
}

h2 {
	font-family: $sans-serif-font-family;

	span {
		display: block;
		font-weight: $w-regular;

		&:last-child {
			margin-top: var(--sp-0\.5);
			color: var(--color-secondary);
		}
	}
}
</style>
