<template>
	<svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 17.5 23.5">
		<path fill="currentColor" d="M16.8 23.5H.8c-.4 0-.8-.3-.8-.8V.7C0 .3.3 0 .8 0h10c.2 0 .4.1.5.2l6 6c.1.1.2.3.2.5v16c0 .5-.3.8-.7.8zM1.5 22H16V7.1l-5.6-5.6H1.5V22z"/>
		<path fill="currentColor" d="m10.8.8 3 3 3 3h-6v-6z"/>
		<path fill="currentColor" d="M16.8 7.2h-6c-.3 0-.5-.2-.5-.5v-6c0-.2.1-.4.3-.5.2-.1.4 0 .5.1l6 6c.1.1.2.4.1.5s-.2.4-.4.4zm-5.6-1h4.3L11.2 2v4.2z"/>
		<g>
			<path d="M12 14.8H5.5c-.4 0-.8-.3-.8-.8s.3-.8.8-.8H12c.4 0 .8.3.8.8s-.4.8-.8.8z"/>
			<path d="M8.8 18c-.5 0-.8-.3-.8-.8v-6.5c0-.4.3-.7.8-.7s.8.3.8.8v6.5c-.1.4-.4.7-.8.7z"/>
		</g>
	</svg>
</template>
