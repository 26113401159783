<template>
	<div role="group" aria-labelledby="langLabel">
		<div class="sr-only" id="langLabel">{{ $t('account.select-language') }}</div>
		<div v-for="language in languages" :key="language.iso">
			<input type="radio" name="lang" v-model="$i18n.locale" @change="saveLanguage()" :id="language.iso" :value="language.iso" class="sr-only">
			<label :for="language.iso">
				<icon-check class="icon"></icon-check>{{ language.title }}
			</label>
		</div>
	</div>
	<span class="sr-only" aria-live="polite">
		{{ $t( 'account.selected-language' ) }} : {{ currentLanguage.title }}
	</span>
</template>

<script>
import availableLanguages from '../lang/availableLanguages';
import { IconCheck } from '@/components/icons/';

export default {
	name: 'SelectLanguageField',
	props: [ 'label' ],
	components: {
		IconCheck,
	},
	data: () => ({
		languages: availableLanguages,
	}),
	computed: {
		currentLanguage() {
			const currentLanguage = this.$i18n.locale
			? this.$i18n.locale
			: this.$i18n.fallbackLocale;
			return availableLanguages.find((language) => {
				return language.iso === currentLanguage;
			});
		},
	},
	methods: {
		saveLanguage() {
			localStorage.setItem('language', this.$i18n.locale);
			document.documentElement.setAttribute('lang', this.$i18n.locale);
			document.documentElement.setAttribute('dir', this.$i18n.locale === 'ar' ? 'rtl' : 'ltr');
		},
	},
};
</script>

<style scoped lang="scss">
	@use "./src/assets/scss/abstracts" as *;

	[role="group"] {
		display: flex;
		flex-direction: column;
		row-gap: var(--sp-0\.5);
	}

	label[for] {
		display: flex;
		align-items: center;
		column-gap: var(--sp);
		width: 100%;
		padding: var(--sp-0\.5) var(--sp);
		margin: 0;
		border: rem(1px) solid transparent;
		border-radius: 10em;
		background-color: transparent;
		transition: background-color .2s, border-color .2s;

		&:hover,
		:focus + & {
			border-color: var(--color-primary);
		}

		:checked + & {
			--icon-o: 1;
			font-weight: $w-regular;
			background-color: var(--color-white)
		}
	}

	.icon {
		opacity: var(--icon-o, 0);
		transition: opacity .2s;
	}
</style>
