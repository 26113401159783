<template>
	<div class="bg-grey-1 pt-1.5 pb-2">
		<div class="container mb-0">
			<h2 class="h1 font-serif text-secondary">{{ $t('login.login-now') }}</h2>
			<SignIn />
		</div>
	</div>
	<div class="container py-2 text-center">
		<h2 class="h1 font-serif text-secondary mb-1.5">{{ $t('login.no-account') }}</h2>
		<router-link :to="{ name: 'Register Step 1' }" class="btn mb-2">
			{{ $t('login.create-account-link-label') }}
			<icon-chevron-right></icon-chevron-right>
		</router-link>
		<div class="mt">
			<router-link :to="{ name: 'Reassurances' }" class="fs-3">
				<icon-question></icon-question>{{ $t('reassurance.title') }}
			</router-link>
		</div>
		<div class="mt">
			<router-link :to="{ name: 'Steps' }" class="fs-3">
				<icon-question></icon-question>{{ $t('steps.title') }}
			</router-link>
		</div>
	</div>
</template>

<script>
import SignIn from '@/components/SignIn.vue';
import { IconChevronRight, IconQuestion } from '@/components/icons';

export default {
	components: {
		SignIn,
		IconChevronRight,
		IconQuestion,
	},
};
</script>

<style lang="scss" scoped>
	@use "./src/assets/scss/abstracts" as *;
</style>
