<template>
	<div>
		<video src="@/assets/video/how-it-works.mp4" muted controls autoplay playsinline loop aria-describedby="altVideoHowItWorks"></video>
	</div>
	<div class="sr-only" id="altVideoHowItWorks">
		<h2>{{ $t('howItWorks.video.title') }}</h2>
		<p>{{ $t('howItWorks.video.text') }}</p>
		<ol>
			<li>{{ $t('howItWorks.video.list-1') }}</li>
			<li>{{ $t('howItWorks.video.list-2') }}</li>
			<li>{{ $t('howItWorks.video.list-3') }}</li>
		</ol>
	</div>
</template>

<style lang="scss" scoped>
	@use "./src/assets/scss/abstracts" as *;

	div {
		background-color: var(--color-black);
	}

	video {
		width: 100%;
		height: var(--full-height);
		object-fit: contain;
	}
</style>
