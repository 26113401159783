import { createApp } from 'vue';

import App from './App.vue';
import router from './router';
import store from './store';
import initAxios from './libs/initAxios';
import initI18n from './libs/initI18n';

initAxios();
createApp(App).use(initI18n()).use(store).use(router).mount('#app');
