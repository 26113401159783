<template>
	<div class="container">
		<div class="row">
			<div class="col-6">
				<h1>Sliders</h1>
				<hr>
			</div>
		</div>
	</div>
	<div class="mt">
		<Slider></Slider>
	</div>
	<div class="mt">
		<Slider fullscreen="true" :slides="slidesFullscreen"></Slider>
	</div>
	<div class="mt">
		<Slider fullscreen="true" numbered="true" :slides="slidesFullscreen"></Slider>
	</div>
</template>

<script>
import Slider from '@/components/content/Slider.vue';

export default {
	components: {
		Slider,
	},

	computed: {
		slidesFullscreen() {
			return [
				{
					id: '1',
					title: this.$t('reassurance.title-1'),
					text: this.$t('reassurance.text-1'),
					img: 'https://picsum.photos/375/460',
				},
				{
					id: '2',
					title: this.$t('reassurance.title-2'),
					text: this.$t('reassurance.text-2'),
					img: 'https://picsum.photos/375/465',
				},
				{
					id: '3',
					title: this.$t('reassurance.title-3'),
					text: this.$t('reassurance.text-3'),
					img: 'https://picsum.photos/375/470',
				},
			];
		},
	},
};
</script>
