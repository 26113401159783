<template>
  <p :style="secondary ? '--bg: var(--color-slip)' : null">
		<span v-html="text"></span>
    <a :href="link" class="light" v-if="link">{{ linkText }}</a>
  </p>
</template>

<script>
export default {
	name: 'Panel',
	props: [ 'secondary', 'text', 'link', 'linkText' ],
};
</script>

<style lang="scss" scoped>
	@use "./src/assets/scss/abstracts" as *;

	p {
		--p: var(--sp);
		display: flex;
		flex-direction: column;
		padding: var(--p);
		border-radius: rem(8px);
		font-size: $fs-3;
		color: #fff;
		background-color: var(--bg, var(--color-primary));
	}

	a {
		margin-top: var(--p);
		margin-inline-start: auto;

		&:hover {
			outline-offset: rem(4px);
		}
	}
</style>
