<template>
	<svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.5 22.67157">
		<path fill="currentColor" d="M20.75 22.67157h-20a.7502.7502 0 0 1-.75-.75v-20a.7502.7502 0 0 1 .75-.75h10a.75.75 0 0 1 0 1.5H1.5v18.5H20v-9.25a.75.75 0 0 1 1.5 0v10a.7502.7502 0 0 1-.75.75Z"/>
		<g>
			<path d="m11.5123 13.81617.1838.7271.2003-.0506.1462-.1462Zm-2.82848-2.8284-.53033-.5304-.14613.1462-.05065.2003Zm-.95711 3.7855-.72711-.1838a.75.75 0 0 0 .911.9109ZM20.6339 3.63388l-9.652 9.652 1.0607 1.0606 9.6519-9.65191ZM9.21415 11.51807l9.652-9.652L17.8055.80545l-9.652 9.65192Zm2.11425 1.571-3.78553.9571.36769 1.4542 3.78554-.9571Zm-2.87457 1.8681.95711-3.7856-1.45423-.3677-.95711 3.7856ZM20.6339 1.86611a1.2501 1.2501 0 0 1 0 1.76777l1.0606 1.06069a2.7499 2.7499 0 0 0 0-3.88909ZM21.6945.80545a2.7499 2.7499 0 0 0-3.889 0l1.0606 1.06066a1.25 1.25 0 0 1 1.7678 0Z"/>
			<path d="m16.92185 2.74966577.70709008-.70696792 2.8281262 2.8286149-.70709009.70696791z"/>
			<path d="m15.50777661 4.16422217.70709008-.70696791 2.8281262 2.8286149-.70709008.70696791z"/>
		</g>
	</svg>
</template>
