<template>
	<div v-if="showModal" @keydown.esc="$emit('close')">
		<div tabindex="0" @focus="focusTrapStart" ref="startFocus"></div>
		<div role="dialog" ref="modal" tabindex="-1" aria-labelledby="modalTitle" aria-modal="true" class="modal" >
			<button type="button" ref="close" class="btn btn--white modal__close" :title="$t('misc.close')" @click="$emit('close')">
				<icon-x></icon-x>
			</button>
			<div class="modal__wrap">
				<div class="modal__content">
					<div class="modal__header">
						<h2 id="modalTitle" class="text-secondary">
							<slot name="header">
							</slot>
						</h2>
					</div>
					<div class="modal__body">
						<slot name="body">
						</slot>
					</div>
				</div>
			</div>
		</div>
		<div tabindex="0" @focus="focusTrapEnd" ref="endFocus"></div>
	</div>
</template>

<script>
import { IconX } from '@/components/icons';

export default {
	name: 'Modal',
	props: [ 'showModal' ],
	components: {
		IconX,
	},
	updated() {
		if (this.$refs.modal && !document.querySelector('body').classList.contains('modal-is-open')) {
			this.$refs.modal.focus();
			document.querySelector('body').classList.add('modal-is-open');
		} else if (!this.$refs.modal) {
			document.querySelector('body').classList.remove('modal-is-open');
			document.querySelector('#title-page').focus();
		}
	},
	methods: {
		focusTrapStart() {
			this.$refs.modal.focus();
		},
		focusTrapEnd() {
			this.$refs.close.focus();
		},
	},
};
</script>

<style scoped lang="scss">
	@use "./src/assets/scss/abstracts" as *;

	:global(.modal-is-open) {
		height: var(--viewport-height);
		overflow: hidden;
	}

	@keyframes modal-open {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}

	@keyframes modal-wrap-open {
		from {
			transform: translateY(-25%);
		}
		to {
			transform: none;
		}
	}

	.modal {
		--p: var(--sp-1\.5);
		position: fixed;
		z-index: var(--z-max);
		top: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		width: 100%;
		height: var(--viewport-height);
		background-color: rgba(0, 0, 0, .5);
		overflow: auto;
		animation: modal-open .4s forwards;

		&__close {
			align-self: flex-end;
			margin: var(--p);
		}

		&__wrap {
			display: flex;
			justify-content: center;
			width: 100%;
			padding: 0 var(--p) var(--p);
			margin: auto;
			animation: modal-wrap-open .4s forwards;
		}

		&__content {
			width: rem(480px);
			max-width: 100%;
			padding: rem(24px);
			border-radius: rem(8px);
			box-shadow: 0 rem(8px) rem(32px) rgba(45, 25, 38, 0.08);
			background-color: var(--color-white);
			overflow: auto;
		}

		&__header {
			padding-bottom: var(--p);
			border-bottom: rem(1px) solid var(--color-grey-1);
			margin-bottom: var(--p);
		}
	}
</style>
