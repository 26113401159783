<template>
	<article>
		<dl>
			<div class="icon">
				<icon-file></icon-file>
			</div>
			<div class="header">
				<div>
					<dt :aria-label="$t('slipCard.vro')"></dt>
					<dd class="vro">{{ $t('slip.vro.' + slip.vroId) }}</dd>
				</div>
				<div>
					<dt :aria-label="$t('slipCard.number')"></dt>
					<dd class="font-serif text-break"><span aria-hidden="true">{{ $t('slipCard.n') }}</span>&nbsp;{{ slip.number }}</dd>
				</div>
				<div class="mt-a">
					<template v-if="abstract">
						<dt :aria-label="$t('slipCard.createdAt')"></dt>
						<dd class="date">
							<icon-calendar class="mr-0.5"></icon-calendar>
							{{ formatedDate(slip.createdAt) }}
						</dd>
					</template>
					<template v-else>
						<dt class="sr-only">{{ $t('slipCard.status') }}</dt>
						<dd class="status" :style="!isPayable ? '--bg: var(--color-warning)' : ''">
							<div class="status__icon">
								<icon-info></icon-info>
							</div>
							{{ $t('slip.status.' + slip.statusCode) }}
						</dd>
					</template>
				</div>
			</div>
			<div class="content" v-if="abstract == null">
				<div>
					<dt>{{ $t('slipCard.createdAt') }}</dt>
					<dd>{{ formatedDate(slip.createdAt) }}</dd>
				</div>
				<div>
					<dt></dt>
					<dd></dd>
				</div>
				<div>
					<dt>{{ $t('slipCard.amount') }}</dt>
					<dd>{{ slip.totalTTC }} €</dd>
				</div>
				<div style="--c: var(--color-secondary)">
					<dt>{{ $t('slipCard.refund') }}</dt>
					<dd class="fw-bold">{{ slip.totalRefund }} €</dd>
				</div>
			</div>
		</dl>
	</article>
</template>

<script>
import { SLIP_STATUS_PAYABLE } from '@/store/slip';
import { IconFile, IconInfo, IconCalendar } from '@/components/icons';

export default {
	props: [ 'slip', 'abstract' ],
	components: {
		IconFile,
		IconInfo,
		IconCalendar,
	},
	computed: {
		isPayable() {
			return this.slip.statusCode === SLIP_STATUS_PAYABLE;
		},
	},
	methods: {
		formatedDate(date) {
			return date.toLocaleDateString(this.$i18n.locale, { year: 'numeric', month: '2-digit', day: '2-digit' });
		},
	},
};
</script>

<style lang="scss" scoped>
	@use "./src/assets/scss/abstracts" as *;

	article {
		--icon-size: #{rem(80px)};
		--gap: var(--sp);
		padding: var(--gap);
		border-radius: rem(16px);
		background-color: var(--color-grey-1);
	}

	.icon {
		display: flex;
		align-items: center;
		justify-content: center;
		height: var(--icon-size);
		border-radius: rem(8px);
		font-size: rem(22px);
		background-color: var(--color-slip);
		color: var(--color-white);
	}

	dl {
		display: grid;
		gap: var(--gap);
		grid-template-columns: var(--icon-size) 1fr 1fr;
		margin: 0;
	}

	dt {
		font-weight: $w-semibold;
		font-size: 0.8em;
		color: var(--c, var(--color-passport));
	}

	dd {
		margin: 0;
		color: var(--c, inherit);
	}

	.header {
		grid-column: 2/-1;
		display: flex;
		flex-direction: column;
		row-gap: var(--sp-0\.5);
	}

	.content {
		display: grid;
		gap: var(--gap);
		grid-template-columns: 1fr 1fr;
		grid-column: 1/-1;
		padding-top: var(--gap);
		border-top: rem(1px) solid var(--color-grey-2);
		font-size: $fs-3;
	}

	.vro {
		font-size: $fs-3;
		font-weight: $w-bold;
		color: var(--color-slip);
	}

	.date,
	.status {
		display: flex;
		align-items: baseline;
		font-size: $fs-3;
	}

	.status {
		&__icon {
			flex: 0 0 auto;
			display: flex;
			align-items: center;
			justify-content: center;
			width: rem(18px);
			height: rem(18px);
			margin-inline-end: var(--sp-0\.5);
			border-radius: 50%;
			color: var(--color-white);
			background-color: var(--bg, var(--color-success));
		}

		svg {
			font-size: 0.924em;
		}
	}
</style>
