<template>
	<header role="banner">
		<template v-if="back.title || !logo || back.history">
			<h1 v-if="title">{{ $t(title) }}</h1>
			<router-link
				class="btn btn--ghost back"
				:to="{ name: back.routeName }"
				:title="$t(back.title)"
				v-if="back.title"
			>
				<icon-arrow-left></icon-arrow-left>
			</router-link>
			<a
				class="btn btn--ghost back"
				v-else-if="back.history"
				@click="goBack"
			>
				<icon-arrow-left></icon-arrow-left>
			</a>
		</template>
		<template v-else>
			<h1>
				<img src="@/assets/img/svg/logo.svg" :alt="$t(title)" width="98" height="34">
			</h1>
		</template>
		<router-link :to="{ name: 'Slip' }" class="btn btn--white cart" v-if="cash" :title="$t('misc.go-to-refunds')">
			<icon-euro-circle-full class="dual" v-if="totalRefund > 0"></icon-euro-circle-full>
			<icon-euro-circle class="dual" v-else></icon-euro-circle>
			{{ totalRefund }} <span class="sr-only">€</span>
		</router-link>
		<router-link to="/select-language" class="btn btn--ghost link" v-if="showLanguageSelect" :title="$t('account.change-language')">
			{{ currentLanguage.title }}
		</router-link>
		<router-link :to="{ name: secondaryLink.routeName }" class="btn btn--ghost link" v-else-if="secondaryLink.title">
			{{ $t(secondaryLink.title) }}
		</router-link>
	</header>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { IconArrowLeft, IconEuroCircle, IconEuroCircleFull } from '@/components/icons/';
import availableLanguages from '@/lang/availableLanguages';

export default {
	name: 'Header',
	components: {
		IconArrowLeft,
		IconEuroCircle,
		IconEuroCircleFull,
	},
	computed: {
		...mapGetters('slip', [ 'totalRefund' ]),
		title() {
			return _.get(this.$route, 'meta.title', '');
		},
		logo() {
			return _.get(this.$route, 'meta.header.logo', true);
		},
		showLanguageSelect() {
			return _.get(this.$route, 'meta.header.showLanguageSelect', false);
		},
		back() {
			return _.get(this.$route, 'meta.header.back', false);
		},
		cash() {
			return _.get(this.$route, 'meta.header.cash', false);
		},
		secondaryLink() {
			return _.get(this.$route, 'meta.header.secondaryLink', false);
		},
		currentLanguage() {
			const currentLanguage = this.$i18n.locale
			? this.$i18n.locale
			: this.$i18n.fallbackLocale;
			return availableLanguages.find((language) => {
				return language.iso === currentLanguage;
			});
		},
	},
	methods: {
		goBack() {
			// eslint-disable-next-line no-unused-expressions
			window.history.length > 1 ? this.$router.go(-1) : this.$router.push({ name: 'Home' });
		},
	},
};
</script>

<style lang="scss" scoped>
	@use "./src/assets/scss/abstracts" as *;

	header {
		position: sticky;
		top: 0;
		z-index: var(--z-header);
		display: flex;
		column-gap: var(--gutter);
		align-items: center;
		height: var(--header-height);
		padding: 0 var(--gutter);
		border-block-end: 1px solid var(--color-grey-1);
		background-color: var(--color-white);
	}

	.back {
		order: -1;
	}

	h1 {
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		margin: 0;
		font-family: $sans-serif-font-family;
		font-weight: $w-regular;
		font-size: $fs-2;
	}

	.cart {
		margin-inline-start: auto;
		margin-inline-end: calc(var(--gutter) * -1);
		column-gap: var(--sp-0\.5);

		[dir="ltr"] & {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}

		[dir="rtl"] & {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}

		svg {
			width: 1.5em;
			height: 1.5em;
			color: var(--color-secondary);
		}
	}

	.link {
		--px: var(--sp-0\.5);
		--py: var(--sp-0\.5);
		flex: 0 0 auto;
		margin-inline-start: auto;
		font-size: $fs-3
	}
</style>
