<template>
	<div class="slider" :class="fullscreen ? 'slider--fullscreen' : null" ref="slider">
		<template v-if="fullscreen">
			<button type="button" tabIndex="-1" class="btn btn--white nav nav--prev" aria-hidden="true" @click="swipe" data-dir="prev" disabled>
				<icon-chevron-left></icon-chevron-left>
			</button>
			<button type="button" tabIndex="-1" class="btn btn--white nav nav--next" aria-hidden="true" @click="swipe" data-dir="next">
				<icon-chevron-right></icon-chevron-right>
			</button>
		</template>
		<div class="slider__content" @scroll="updateBullets">
			<template v-if="fullscreen">
				<div v-for="slide in slides" :key="slide.id" class="fs-item" :style="slide.img ? '--bg: url('+slide.img+')' : null">
					<section class="fs-item__content" :class="numbered ? 'fs-item__content--numbered' : null">
						<h2>{{ slide.title }}</h2>
						<p>{{ slide.text }}</p>
					</section>
				</div>
			</template>
			<template v-else-if="card">
				<div v-for="slide in card" :key="slide.id">
					<Card :card="slide"></Card>
				</div>
			</template>
			<template v-else-if="slip">
				<div v-for="slide in slip" :key="slide.id">
					<SlipCard :slip="slide" abstract="true"></SlipCard>
				</div>
			</template>
			<template v-else-if="operation">
				<div v-for="slide in operation" :key="slide.id">
					<Operation :slip="slide"></Operation>
				</div>
			</template>
		</div>
		<div class="slider__bullets" aria-hidden="true">
			<div></div>
		</div>
	</div>
</template>

<script>
import { IconChevronLeft, IconChevronRight } from '@/components/icons';
import Card from '@/components/content/Card.vue';
import SlipCard from '@/components/SlipCard.vue';
import Operation from '@/components/Operation.vue';

export default {
	name: 'Slider',
	props: [ 'fullscreen', 'numbered', 'slides', 'card', 'slip', 'operation' ],
	components: {
		IconChevronLeft,
		IconChevronRight,
		Card,
		SlipCard,
		Operation,
	},
	mounted() {
		const bullet = this.$refs.slider.querySelector('.slider__bullets div').outerHTML;
		for (let i = 1; i < this.$refs.slider.querySelectorAll('.slider__content > div').length; i++) {
			this.$refs.slider.querySelector('.slider__bullets').insertAdjacentHTML('beforeend', bullet);
		}
		this.$refs.slider.querySelector('.slider__bullets div:first-child').classList.add('active');
	},
	methods: {
		swipe($event) {
			const slider = $event.target.closest('.slider');
			const items = slider.querySelector('.slider__content').children;
			const current = slider.querySelector('.slider__bullets .active');
			const currentIndex = [ ...current.parentElement.children ].indexOf(current);
			const dir = $event.target.getAttribute('data-dir') === 'next' ? 1 : -1;
			const newIndex = currentIndex + dir;
			items[newIndex].scrollIntoView();
		},
		updateNav(slider, newIndex) {
			const navPrev = slider.querySelector('[data-dir="prev"]');
			const navNext = slider.querySelector('[data-dir="next"]');
			const items = slider.querySelector('.slider__content').children;
			if (navPrev && navNext) {
				if (newIndex === 0) {
					navPrev.setAttribute('disabled', '');
				}
				if (newIndex === (items.length - 1)) {
					navNext.setAttribute('disabled', '');
				}
				if (newIndex < (items.length - 1) && newIndex > 0) {
					navPrev.removeAttribute('disabled');
					navNext.removeAttribute('disabled');
				}
			}
		},
		updateBullets($event) {
			const bulletsContainer = $event.target.closest('.slider').querySelector('.slider__bullets');
			const items = $event.target.children;
			for (let i = 0; i < items.length; i++) {
				const leftItem = document.documentElement.getAttribute('dir') === 'rtl' ? items[i].getBoundingClientRect().left * -1 : items[i].getBoundingClientRect().left;
				if (leftItem < (window.innerWidth / 2)) {
					bulletsContainer.querySelector('.active').classList.remove('active');
					bulletsContainer.querySelector(`:nth-child(${i + 1})`).classList.add('active');
					this.updateNav($event.target.closest('.slider'), i);
				}
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	@use "./src/assets/scss/abstracts" as *;

	.slider {
		--item-m: var(--sp);

		&--fullscreen {
			--content-h: calc(var(--full-height) - var(--container-cta-login-height));
			--bullets-position: absolute;
			--item-width: 100%;
			--item-m: 0;

			position: relative;
			counter-reset: number;
		}

		&__content {
			display: flex;
			min-height: var(--content-h, 0);
			scroll-snap-type: x mandatory;
			overflow-y: hidden;
			overflow-x: auto;
			scroll-behavior: smooth;

			> div {
				flex: 0 0 var(--item-width, 85%);
				scroll-snap-align: center;
				margin-inline-end: var(--item-m);

				&:first-child {
					margin-inline-start: var(--item-m);
				}
			}
		}

		&__bullets {
			position: var(--bullets-position, static);
			left: var(--sp-2);
			right: var(--sp-2);
			bottom: var(--sp-2);
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			margin-top: var(--sp-1\.5);

			> div {
				width: rem(9px);
				height: rem(9px);
				margin: var(--sp-0\.25);
				border: 1px solid rgba(0, 0, 0, .15);
				border-radius: 50%;
				background-color: var(--color-white);
				transition: background-color .2s, border-color .2s;

				&.active {
					border-color: transparent;
					background-color: var(--color-secondary);
				}
			}
		}
	}

	.fs-item {
		position: relative;
		z-index: 0;
		padding: var(--sp-2) var(--sp-1\.5);
		background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 90%), var(--bg);
		background-size: cover;

		&__content {
			padding: var(--sp-1\.5);
			border-radius: rem(4px);
			backdrop-filter: blur(10px);
			background-color: rgba(0, 0, 0, .33);

			&--numbered {
				display: grid;
				grid-template-columns: auto 1fr;
				grid-template-rows: auto auto;

				&::before {
					content: counter(number);
					counter-increment: number;
					grid-row: 1/-1;
					padding-right: var(--sp);
					font-size: rem(44px);
					font-weight: $w-bold;
					color: var(--color-white);
				}
			}
		}

		h2 {
			font-size: $fs-1;
			color: var(--color-white);
		}

		p {
			font-size: $fs-3;
			color: var(--color-white);
		}

		img {
			position: absolute;
			z-index: -1;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.nav {
		--fs: #{rem(11px)};
		position: absolute;
		z-index: 1;
		top: 50%;
		transform: translateY(-50%);

		&--prev {
			inset-inline-start: var(--sp);
		}

		&--next {
			inset-inline-end: var(--sp);
		}
	}
</style>
