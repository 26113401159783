<template>
	<div v-if="sortedSlipsByStatusPayableAndCustomsWaiting.length || sortedSlipsByStatusPaid.length">
		<div class="pt-1.5 mb-2" v-if="sortedSlipsByStatusPayableAndCustomsWaiting.length">
			<div class="container mb">
				<div class="row align-baseline">
					<div class="col">
						<h2 class="fs-1">{{ $t('welcome.last-slips') }}</h2>
					</div>
					<div class="col-auto">
						<router-link :to="{ name: 'Slip' }" class="fs-3">{{ $t('welcome.see-all-slips') }}</router-link>
					</div>
				</div>
			</div>
			<Slider :slip="sortedSlipsByStatusPayableAndCustomsWaiting.slice(0,3)" />
		</div>
		<div class="my-2" v-if="sortedSlipsByStatusPaid.length">
			<div class="container mb-1.5">
				<div class="row align-baseline">
					<div class="col-auto">
						<h2 class="fs-1">
							{{ $t('welcome.last-operations') }}
						</h2>
					</div>
					<div class="col-auto ml-a">
						<router-link :to="{ name: 'History' }" class="fs-3">{{ $t('welcome.see-all-operations') }}</router-link>
					</div>
				</div>
			</div>
			<Slider :operation="sortedSlipsByStatusPaid.slice(0,3)" />
		</div>
	</div>
	<div v-else class="container pt-1.5">
		<Panel
			:text="$t('slip.alert-add-passport.text')"
			link="/account-passport"
			:linkText="$t('slip.alert-add-passport.link')"
			v-if="!user.passport"
		/>
		<Panel :text="$t('welcome.no-slip')" secondary="true"></Panel>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Panel from '@/components/content/Panel.vue';
import Slider from '@/components/content/Slider.vue';

export default {
	name: 'Welcome',
	components: {
		Panel,
		Slider,
	},
	computed: {
		...mapState('authentication', [ 'user' ]),
		...mapGetters('slip', [
			'sortedSlipsByStatusPaid',
			'sortedSlipsByStatusPayableAndCustomsWaiting',
		]),
	},
};
</script>
