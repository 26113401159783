<template>
	<div class="container pt-1.5">
		<section aria-labelledby="sectionInfos">
			<h2 id="sectionInfos" class="h1">{{ $t('account.your-informations') }}</h2>
			<link-account link="account-passport" :text="$t('account.passport')" :title="$t('account.passport-title')" bgIcon="passport"><icon-passport/></link-account>
			<link-account link="account-connection-information" :text="$t('account.connection')" :title="$t('account.connection-title')"><icon-locked/></link-account>
		</section>
		<section aria-labelledby="sectionApp">
			<h2 id="sectionApp" class="h1">{{ $t('account.your-app') }}</h2>
			<link-account link="account-update-language" :text="$t('account.change-language')" bgIcon="primary"><icon-talk/></link-account>
			<div class="my-2">
				<div class="my">
					<router-link :to="{ name: 'Reassurances' }" class="fs-3">
						<icon-question></icon-question>{{ $t('reassurance.title') }}
					</router-link>
				</div>
				<div class="my">
					<router-link :to="{ name: 'Steps' }" class="fs-3">
						<icon-question></icon-question>{{ $t('steps.title') }}
					</router-link>
				</div>
			</div>
			<div class="text-center">
				<button @click="logout" type="button" class="btn"><icon-power/>{{ $t('misc.disconnection') }}</button>
			</div>
		</section>
	</div>
</template>

<script>
import LinkAccount from '@/components/LinkAccount.vue';
import { IconPassport, IconLocked, IconTalk, IconPower, IconQuestion } from '@/components/icons';

export default {
	components: {
		LinkAccount,
		IconPassport,
		IconLocked,
		IconTalk,
		IconPower,
		IconQuestion,
	},
	name: 'Account',
	methods: {
		logout() {
			this.$store.commit('authentication/clearLoginContext');
			this.$router.push({ name: 'Login' });
		},
	},
};
</script>

<style scoped lang="scss">
	section {
		margin-bottom: var(--sp-2);
	}
</style>
