import axios from 'axios';

const PassportService = {
	async add({ passportNumber, countryIso	}) {
		try {
			const { data } = await axios.post('/AddPassport', {
				PassportNumber: passportNumber,
				Iso: countryIso,
			});

			return {
				succeeded: data.IsSuccess,
				messages: data.Messages,
				code: data.Code,
			};
		} catch (error) {
			return {
				succeeded: false,
				code: 500,
			};
		}
	},
};

export default PassportService;
